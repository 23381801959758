import { DropdownOption } from 'andromeda/Dropdown';

export const MAP_COLORS = ['#808080', '#8042E3', '#DF2960', '#0EBFF6', '#C839DF', '#0082ac'];
export const TABS = ['Revenue', 'Orders Influenced', 'Stores Influenced'];

export const TabsToMetricType = {
    Revenue: undefined,
    'Orders Influenced': 'orders_influenced',
    'Stores Influenced': 'stores_influenced',
};
export const DROPDOWN_OPTIONS: DropdownOption[] = TABS.map(tab => ({
    label: tab,
    value: tab,
}));
export const STORE_HEADERS = { 'SKU Name': 'w-2/3', 'SKU ID': 'w-1/4', Quantity: 'w-1/6' };
export const STATE_NAME_MAP = {
    AL: 'Alabama',
    AK: 'Alaska',
    AS: 'American Samoa',
    AZ: 'Arizona',
    AR: 'Arkansas',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DE: 'Delaware',
    DC: 'District Of Columbia',
    FM: 'Federated States Of Micronesia',
    FL: 'Florida',
    GA: 'Georgia',
    GU: 'Guam',
    HI: 'Hawaii',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    IA: 'Iowa',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    ME: 'Maine',
    MH: 'Marshall Islands',
    MD: 'Maryland',
    MA: 'Massachusetts',
    MI: 'Michigan',
    MN: 'Minnesota',
    MS: 'Mississippi',
    MO: 'Missouri',
    MT: 'Montana',
    NE: 'Nebraska',
    NV: 'Nevada',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'New Mexico',
    NY: 'New York',
    NC: 'North Carolina',
    ND: 'North Dakota',
    MP: 'Northern Mariana Islands',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PW: 'Palau',
    PA: 'Pennsylvania',
    PR: 'Puerto Rico',
    RI: 'Rhode Island',
    SC: 'South Carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UT: 'Utah',
    VT: 'Vermont',
    VI: 'Virgin Islands',
    VA: 'Virginia',
    WA: 'Washington',
    WV: 'West Virginia',
    WI: 'Wisconsin',
    WY: 'Wyoming',
};

export const countryCoords = {
    EC: {
        longitude: -78.1834,
        latitude: -1.8312,
        zoom: 4,
    },
    PH: {
        longitude: 123.02064333085153,
        latitude: 11.863578887647495,
        zoom: 5,
    },
};

export const ECStateCoords = {
    PICHINCHA: {
        longitude: -78.5125,
        latitude: -0.22,
        zoom: 7,
    },
    ORELLANA: {
        latitude: -0.4625,
        longitude: -76.9842,
        zoom: 7,
    },
    GUAYAS: {
        longitude: -79.8875,
        latitude: -2.19,
        zoom: 7,
    },
    AZUAY: {
        longitude: -79.0045,
        latitude: -2.8974,
        zoom: 7,
    },
    'SANTO DOMINGO DE LOS TSÁCHILAS': {
        longitude: -79.1719,
        latitude: -0.2542,
        zoom: 7,
    },
    'EL ORO': {
        longitude: -79.9667,
        latitude: -3.2667,
        zoom: 7,
    },
    MANABI: {
        longitude: -80.7162,
        latitude: -0.95,
        zoom: 7,
    },
    'MORONA-SANTIAGO': {
        longitude: -78.17,
        latitude: -2.46,
        zoom: 7,
    },
    LOJA: {
        longitude: -79.2,
        latitude: -3.9833,
        zoom: 7,
    },
    TUNGURAHUA: {
        longitude: -78.6197,
        latitude: -1.2417,
        zoom: 7,
    },
    ESMERALDAS: {
        longitude: -79.6528,
        latitude: 0.9667,
        zoom: 7,
    },
    'LOS RIOS': {
        longitude: -79.45,
        latitude: -1.0333,
        zoom: 7,
    },
    CHIMBORAZO: {
        longitude: -78.6483,
        latitude: -1.6731,
        zoom: 7,
    },
    IMBABURA: {
        longitude: -78.1307,
        latitude: 0.3627,
        zoom: 7,
    },
    COTOPAXI: {
        longitude: -78.6161,
        latitude: -0.9319,
        zoom: 7,
    },
    CARCHI: {
        longitude: -77.7186,
        latitude: 0.8117,
        zoom: 7,
    },
    SUCUMBÍOS: {
        longitude: -76.8828,
        latitude: 0.0847,
        zoom: 7,
    },
    'SANTA ELENA': {
        longitude: -80.8583,
        latitude: -2.2267,
        zoom: 7,
    },
    CAÑAR: {
        longitude: -78.8333,
        latitude: -2.7333,
        zoom: 7,
    },
    PASTAZA: {
        longitude: -78.0028,
        latitude: -1.4861,
        zoom: 7,
    },
    BOLIVAR: {
        longitude: -79.0,
        latitude: -1.6,
        zoom: 7,
    },
    NAPO: {
        longitude: -77.9167,
        latitude: -0.9167,
        zoom: 7,
    },
    GALAPAGOS: {
        longitude: -90.3167,
        latitude: -0.75,
        zoom: 7,
    },
    'ZAMORA-CHINCHIPE': {
        longitude: -78.9333,
        latitude: -4.0667,
        zoom: 7,
    },
};

const ECCityCoords = {
    'DISTRITO METROPOLITANO DE QUITO': {
        latitude: -0.22,
        longitude: -78.5125,
        zoom: 8,
    },
    GUAYAQUIL: {
        latitude: -2.19,
        longitude: -79.8875,
        zoom: 8,
    },
    CUENCA: {
        latitude: -2.8974,
        longitude: -79.0045,
        zoom: 8,
    },
    'SANTO DOMINGO DE LOS COLORADOS': {
        latitude: -0.2542,
        longitude: -79.1719,
        zoom: 8,
    },
    MACHALA: {
        latitude: -3.2667,
        longitude: -79.9667,
        zoom: 8,
    },
    'ELOY ALFARO': {
        latitude: -2.1733,
        longitude: -79.8311,
        zoom: 8,
    },
    MANTA: {
        latitude: -0.95,
        longitude: -80.7162,
        zoom: 8,
    },
    PORTOVIEJO: {
        latitude: -1.0561,
        longitude: -80.4553,
        zoom: 8,
    },
    SUCUA: {
        latitude: -2.46,
        longitude: -78.17,
        zoom: 8,
    },
    LOJA: {
        latitude: -3.9833,
        longitude: -79.2,
        zoom: 8,
    },
    AMBATO: {
        latitude: -1.2417,
        longitude: -78.6197,
        zoom: 8,
    },
    ESMERALDAS: {
        latitude: 0.9667,
        longitude: -79.6528,
        zoom: 8,
    },
    QUEVEDO: {
        latitude: -1.0333,
        longitude: -79.45,
        zoom: 8,
    },
    RIOBAMBA: {
        latitude: -1.6731,
        longitude: -78.6483,
        zoom: 8,
    },
    MILAGRO: {
        latitude: -2.1347,
        longitude: -79.5872,
        zoom: 8,
    },
    IBARRA: {
        latitude: -0.3627,
        longitude: -78.1307,
        zoom: 8,
    },
    'LA LIBERTAD': {
        latitude: -2.2333,
        longitude: -80.9,
        zoom: 8,
    },
    BABAHOYO: {
        latitude: -1.8167,
        longitude: -79.5167,
        zoom: 8,
    },
    LATACUNGA: {
        latitude: -0.9319,
        longitude: -78.6161,
        zoom: 8,
    },
    TULCAN: {
        latitude: -0.8117,
        longitude: -77.7186,
        zoom: 8,
    },
    'NUEVA LOJA': {
        latitude: -0.0847,
        longitude: -76.8828,
        zoom: 8,
    },
    'PUERTO FRANCISCO DE ORELLANA': {
        latitude: -0.4625,
        longitude: -76.9842,
        zoom: 8,
    },
    'SANTA ELENA': {
        latitude: -2.2267,
        longitude: -80.8583,
        zoom: 8,
    },
    AZOGUES: {
        latitude: -2.7333,
        longitude: -78.8333,
        zoom: 8,
    },
    PUYO: {
        latitude: -1.4861,
        longitude: -78.0028,
        zoom: 8,
    },
    GUARANDA: {
        latitude: -1.6,
        longitude: -79,
        zoom: 8,
    },
    TENA: {
        latitude: -0.989,
        longitude: -77.8159,
        zoom: 8,
    },
    MACAS: {
        latitude: -2.3667,
        longitude: -78.1333,
        zoom: 8,
    },
    'PUERTO AYORA': {
        latitude: -0.75,
        longitude: -90.3267,
        zoom: 8,
    },
    ZAMORA: {
        latitude: -4.0692,
        longitude: -78.9567,
        zoom: 8,
    },
    'PUERTO BAQUERIZO MORENO': {
        latitude: -0.9025,
        longitude: -89.6092,
        zoom: 8,
    },
    SANGOLQUI: {
        latitude: -0.3344,
        longitude: -78.4475,
        zoom: 8,
    },
    PELILEO: {
        latitude: -1.3306,
        longitude: -78.5428,
        zoom: 8,
    },
    DAULE: {
        latitude: -1.8667,
        longitude: -79.9833,
        zoom: 8,
    },
    PASAJE: {
        latitude: -3.3269,
        longitude: -79.8049,
        zoom: 8,
    },
    CHONE: {
        latitude: -0.6833,
        longitude: -80.1,
        zoom: 8,
    },
    'SANTA ROSA': {
        latitude: -3.4522,
        longitude: -79.9617,
        zoom: 8,
    },
    HUAQUILLAS: {
        latitude: -3.4814,
        longitude: -80.2432,
        zoom: 8,
    },
    MONTECRISTI: {
        latitude: -1.05,
        longitude: -80.6667,
        zoom: 8,
    },
    SAMBORONDON: {
        latitude: -1.9611,
        longitude: -79.7256,
        zoom: 8,
    },
    JIPIJAPA: {
        latitude: -1.3486,
        longitude: -80.5786,
        zoom: 8,
    },
    OTAVALO: {
        latitude: 0.2303,
        longitude: -78.2625,
        zoom: 8,
    },
    CAYAMBE: {
        latitude: 0.0439,
        longitude: -78.1561,
        zoom: 8,
    },
    VENTANAS: {
        latitude: -1.45,
        longitude: -79.47,
        zoom: 8,
    },
    'SAN JACINTO DE BUENA FE': {
        latitude: -0.8986,
        longitude: -79.4889,
        zoom: 8,
    },
    'VELASCO IBARRA': {
        latitude: -1.0439,
        longitude: -79.6383,
        zoom: 8,
    },
    SALINAS: {
        latitude: -2.2167,
        longitude: -80.9501,
        zoom: 8,
    },
    'LA TRONCAL': {
        latitude: -2.4,
        longitude: -79.33,
        zoom: 8,
    },
    PLAYAS: {
        latitude: -2.63,
        longitude: -80.39,
        zoom: 8,
    },
    MACHACHI: {
        latitude: -0.51,
        longitude: -78.57,
        zoom: 8,
    },
    VINCES: {
        latitude: -1.55,
        longitude: -79.7333,
        zoom: 8,
    },
    'LA CONCORDIA': {
        latitude: -0.0067,
        longitude: -79.3958,
        zoom: 8,
    },
    'ROSA ZARATE': {
        latitude: -0.33,
        longitude: -79.48,
        zoom: 8,
    },
    BALZAR: {
        latitude: -1.36,
        longitude: -79.9,
        zoom: 8,
    },
    NARANJITO: {
        latitude: -2.1667,
        longitude: -79.4653,
        zoom: 8,
    },
    NARANJAL: {
        latitude: -2.6728,
        longitude: -79.615,
        zoom: 8,
    },
    SUCRE: {
        latitude: -1.26,
        longitude: -80.44,
        zoom: 8,
    },
    'LA MANA': {
        latitude: -0.93,
        longitude: -79.22,
        zoom: 8,
    },
    'SAN LORENZO DE ESMERALDAS': {
        latitude: -1.2658,
        longitude: -78.8414,
        zoom: 8,
    },
    ZARUMA: {
        latitude: -3.6833,
        longitude: -79.6,
        zoom: 8,
    },
    CATAMAYO: {
        latitude: -3.9833,
        longitude: -79.35,
        zoom: 8,
    },
    ATUNTAQUI: {
        latitude: 0.3317,
        longitude: -78.2189,
        zoom: 8,
    },
    GUABO: {
        latitude: -3.2388,
        longitude: -79.8292,
        zoom: 8,
    },
    PEDERNALES: {
        latitude: 0.07,
        longitude: -80.055,
        zoom: 8,
    },
    'BAHIA DE CARAQUEZ': {
        latitude: -0.6,
        longitude: -80.4167,
        zoom: 8,
    },
    'PUERTO QUITO': {
        latitude: 0.1272,
        longitude: -79.2531,
        zoom: 8,
    },
    'PEDRO CARBO': {
        latitude: -1.8333,
        longitude: -80.2333,
        zoom: 8,
    },
    VALLE: {
        latitude: -2.95,
        longitude: -78.9833,
        zoom: 8,
    },
    'SAN ANTONIE DE IBARRA': {
        latitude: -0.3627,
        longitude: -78.1307,
        zoom: 8,
    },
    'YAGUACHI NUEVO': {
        latitude: -2.12,
        longitude: -79.69,
        zoom: 8,
    },
    CALCETA: {
        latitude: -0.84,
        longitude: -80.17,
        zoom: 8,
    },
    'BAQUERIZO MORENO': {
        latitude: -1.9167,
        longitude: -79.5167,
        zoom: 8,
    },
    'CAMILO PONCE ENRIQUEZ': {
        latitude: -3.05,
        longitude: -79.7333,
        zoom: 8,
    },
    PINAS: {
        latitude: -3.6806,
        longitude: -79.6806,
        zoom: 8,
    },
    VALENCIA: {
        latitude: -0.9525,
        longitude: -79.3531,
        zoom: 8,
    },
    VALDEZ: {
        latitude: 1.25,
        longitude: -79,
        zoom: 8,
    },
    'SAN GABRIEL': {
        latitude: 0.5983,
        longitude: -77.8853,
        zoom: 8,
    },
    ALAUSI: {
        latitude: -2.19,
        longitude: -78.85,
        zoom: 8,
    },
    RICAURTE: {
        latitude: -2.8667,
        longitude: -78.9333,
        zoom: 8,
    },
    'LOMAS DE SARGENTILLO': {
        latitude: -1.8833,
        longitude: -80.0833,
        zoom: 8,
    },
    PATATE: {
        latitude: -1.3167,
        longitude: -78.5167,
        zoom: 8,
    },
    MUISNE: {
        latitude: 0.6108,
        longitude: -80.0186,
        zoom: 8,
    },
    CARIAMANGA: {
        latitude: -4.32,
        longitude: -79.56,
        zoom: 8,
    },
    BANOS: {
        latitude: -1.3964,
        longitude: -78.4247,
        zoom: 8,
    },
    SININCAY: {
        latitude: -2.8333,
        longitude: -79,
        zoom: 8,
    },
    MACARA: {
        latitude: -4.3833,
        longitude: -79.95,
        zoom: 8,
    },
    'SAN MIGUEL DE SALCEDO': {
        latitude: -1.05,
        longitude: -78.5833,
        zoom: 8,
    },
    CATARAMA: {
        latitude: -1.57,
        longitude: -79.47,
        zoom: 8,
    },
    ZUMBAGUA: {
        latitude: -0.9558,
        longitude: -78.9017,
        zoom: 8,
    },
    'SANTA CRUZ': {
        latitude: -0.5333,
        longitude: -90.35,
        zoom: 8,
    },
    TOSAGUA: {
        latitude: -0.78,
        longitude: -80.26,
        zoom: 8,
    },
    ROCAFUERTE: {
        latitude: -0.92,
        longitude: -80.46,
        zoom: 8,
    },
    'SAN VICENTE': {
        latitude: -1.9,
        longitude: -79.2333,
        zoom: 8,
    },
    BALAO: {
        latitude: -2.91,
        longitude: -79.81,
        zoom: 8,
    },
    'PUERTO VILLAMIL': {
        latitude: -0.9568,
        longitude: -90.9672,
        zoom: 8,
    },
    YAUPI: {
        latitude: -2.8379,
        longitude: -77.9357,
        zoom: 8,
    },
};

const PHStateCoords = {
    CAVITE: {
        latitude: 14.4791,
        longitude: 120.8963,
        zoom: 8,
    },
    BULACAN: {
        latitude: 14.7917,
        longitude: 120.8783,
        zoom: 8,
    },
};

const PHCityCoords = {
    'CITY OF DASMARIÑAS': {
        latitude: 14.3294,
        longitude: 120.9367,
        zoom: 10,
    },
    TANZA: {
        latitude: 14.675,
        longitude: 120.9386,
        zoom: 10,
    },
    'TRECE MARTIRES CITY (Capital)': {
        latitude: 14.2833,
        longitude: 120.867,
        zoom: 10,
    },
    'CITY OF GENERAL TRIAS': {
        latitude: 14.3864,
        longitude: 120.8817,
        zoom: 10,
    },
    NAIC: {
        latitude: 14.3147,
        longitude: 120.7628,
        zoom: 10,
    },
    CARMONA: {
        latitude: 14.3047,
        longitude: 121.0478,
        zoom: 10,
    },
    SILANG: {
        latitude: 14.2167,
        longitude: 120.9667,
        zoom: 10,
    },
    'BACOOR CITY': {
        latitude: 14.4417,
        longitude: 120.9578,
        zoom: 10,
    },
    'IMUS CITY': {
        latitude: 14.4292,
        longitude: 120.9367,
        zoom: 10,
    },
    BOCAUE: {
        latitude: 14.7983,
        longitude: 120.9261,
        zoom: 10,
    },
    'SANTA MARIA (BULACAN)': {
        latitude: 14.8056,
        longitude: 120.9611,
        zoom: 10,
    },
    'SAN RAFAEL (BULACAN)': {
        latitude: 15.1856,
        longitude: 120.9611,
        zoom: 10,
    },
    MARILAO: {
        latitude: 14.7578,
        longitude: 120.9489,
        zoom: 10,
    },
    PULILAN: {
        latitude: 14.9014,
        longitude: 120.8614,
        zoom: 10,
    },
    'CITY OF SAN JOSE DEL MONTE': {
        latitude: 14.8133,
        longitude: 121.045,
        zoom: 10,
    },
    'SAN ILDEFONSO (BULACAN)': {
        latitude: 15.0333,
        longitude: 120.95,
        zoom: 10,
    },
    'PLARIDEL (BULACAN)': {
        latitude: 14.8889,
        longitude: 120.8578,
        zoom: 10,
    },
    BALIUAG: {
        latitude: 14.9542,
        longitude: 120.8961,
        zoom: 10,
    },
    ALFONSO: {
        latitude: 14.1667,
        longitude: 120.8833,
        zoom: 10,
    },
    'GEN. MARIANO ALVAREZ': {
        latitude: 14.2667,
        longitude: 120.9333,
        zoom: 10,
    },
    'ROSARIO (CAVITE)': {
        latitude: 14.3833,
        longitude: 120.85,
        zoom: 10,
    },
    NOVELETA: {
        latitude: 14.4167,
        longitude: 120.8667,
        zoom: 10,
    },
    MARAGONDON: {
        latitude: 14.2,
        longitude: 120.7,
        zoom: 10,
    },
    'GENERAL EMILIO AGUINALDO': {
        latitude: 14.1667,
        longitude: 120.9,
        zoom: 10,
    },
    KAWIT: {
        latitude: 14.4333,
        longitude: 120.9,
        zoom: 10,
    },
    TERNATE: {
        latitude: 14.3,
        longitude: 120.7,
        zoom: 10,
    },
    AMADEO: {
        latitude: 14.1667,
        longitude: 120.9,
        zoom: 10,
    },
    'TAGAYTAY CITY': {
        latitude: 14.1,
        longitude: 120.9333,
        zoom: 10,
    },
    'MAGALLANES (CAVITE)': {
        latitude: 14.15,
        longitude: 120.9333,
        zoom: 10,
    },
    'MENDEZ (MENDEZ-NUÑEZ)': {
        latitude: 14.1,
        longitude: 120.9333,
        zoom: 10,
    },
    'SAN MIGUEL (BULACAN)': {
        latitude: 14.9667,
        longitude: 120.9667,
        zoom: 10,
    },
    'CITY OF MEYCAUAYAN': {
        latitude: 14.75,
        longitude: 120.9667,
        zoom: 10,
    },
    CALUMPIT: {
        latitude: 14.9167,
        longitude: 120.7667,
        zoom: 10,
    },
    'BALAGTAS (BIGAA)': {
        latitude: 14.8167,
        longitude: 120.9,
        zoom: 10,
    },
    BUSTOS: {
        latitude: 14.9167,
        longitude: 120.9,
        zoom: 10,
    },
    GUIGUINTO: {
        latitude: 14.8333,
        longitude: 120.8667,
        zoom: 10,
    },
    ANGAT: {
        latitude: 14.9167,
        longitude: 121.05,
        zoom: 10,
    },
    'HAGONOY (BULACAN)': {
        latitude: 14.8333,
        longitude: 120.8,
        zoom: 10,
    },
    'CITY OF MALOLOS (CAPITAL)': {
        latitude: 14.85,
        longitude: 120.8167,
        zoom: 10,
    },
    NORZAGARAY: {
        latitude: 14.9167,
        longitude: 121.05,
        zoom: 10,
    },
    PANDI: {
        latitude: 14.8833,
        longitude: 120.8167,
        zoom: 10,
    },
    OBANDO: {
        latitude: 14.7333,
        longitude: 120.95,
        zoom: 10,
    },
};

export const usCoords = {
    Alaska: {
        latitude: 61.385,
        longitude: -152.2683,
        zoom: 5.5,
    },
    Alabama: {
        latitude: 32.799,
        longitude: -86.8073,
        zoom: 5.5,
    },
    Arkansas: {
        latitude: 34.9513,
        longitude: -92.3809,
        zoom: 5.5,
    },
    Arizona: {
        latitude: 33.7712,
        longitude: -111.3877,
        zoom: 5.5,
    },
    California: {
        latitude: 36.17,
        longitude: -119.7462,
        zoom: 5.5,
    },
    Colorado: {
        latitude: 39.0646,
        longitude: -105.3272,
        zoom: 5.5,
    },
    Connecticut: {
        latitude: 41.5834,
        longitude: -72.7622,
        zoom: 5.5,
    },
    Delaware: {
        latitude: 39.3498,
        longitude: -75.5148,
        zoom: 5.5,
    },
    Florida: {
        latitude: 27.8333,
        longitude: -81.717,
        zoom: 5.5,
    },
    Georgia: {
        latitude: 32.9866,
        longitude: -83.6487,
        zoom: 5.5,
    },
    Hawaii: {
        latitude: 21.1098,
        longitude: -157.5311,
        zoom: 5.5,
    },
    Iowa: {
        latitude: 42.0046,
        longitude: -93.214,
        zoom: 5.5,
    },
    Idaho: {
        latitude: 44.2394,
        longitude: -114.5103,
        zoom: 5.5,
    },
    Illinois: {
        latitude: 40.3363,
        longitude: -89.0022,
        zoom: 5.5,
    },
    Indiana: {
        latitude: 39.8647,
        longitude: -86.2604,
        zoom: 5.5,
    },
    Kansas: {
        latitude: 38.5111,
        longitude: -96.8005,
        zoom: 5.5,
    },
    Kentucky: {
        latitude: 37.669,
        longitude: -84.6514,
        zoom: 5.5,
    },
    Louisiana: {
        latitude: 31.1801,
        longitude: -91.8749,
        zoom: 5.5,
    },
    Massachusetts: {
        latitude: 42.2373,
        longitude: -71.5314,
        zoom: 5.5,
    },
    Maryland: {
        latitude: 39.0724,
        longitude: -76.7902,
        zoom: 5.5,
    },
    Maine: {
        latitude: 44.6074,
        longitude: -69.3977,
        zoom: 5.5,
    },
    Michigan: {
        latitude: 43.3504,
        longitude: -84.5603,
        zoom: 5.5,
    },
    Minnesota: {
        latitude: 45.7326,
        longitude: -93.9196,
        zoom: 5.5,
    },
    Missouri: {
        latitude: 38.4623,
        longitude: -92.302,
        zoom: 5.5,
    },
    Mississippi: {
        latitude: 32.7673,
        longitude: -89.6812,
        zoom: 5.5,
    },
    Montana: {
        latitude: 46.9048,
        longitude: -110.3261,
        zoom: 5.5,
    },
    'North Carolina': {
        latitude: 35.6411,
        longitude: -79.8431,
        zoom: 5.5,
    },
    'North Dakota': {
        latitude: 47.5362,
        longitude: -99.793,
        zoom: 5.5,
    },
    Nebraska: {
        latitude: 41.1289,
        longitude: -98.2883,
        zoom: 5.5,
    },
    'New Hampshire': {
        latitude: 43.4108,
        longitude: -71.5653,
        zoom: 5.5,
    },
    'New Jersey': {
        latitude: 40.314,
        longitude: -74.5089,
        zoom: 5.5,
    },
    'New Mexico': {
        latitude: 34.8375,
        longitude: -106.2371,
        zoom: 5.5,
    },
    Nevada: {
        latitude: 38.4199,
        longitude: -117.1219,
        zoom: 5.5,
    },
    'New York': {
        latitude: 42.1497,
        longitude: -74.9384,
        zoom: 5.5,
    },
    Ohio: {
        latitude: 40.3736,
        longitude: -82.7755,
        zoom: 5.5,
    },
    Oklahoma: {
        latitude: 35.5376,
        longitude: -96.9247,
        zoom: 5.5,
    },
    Oregon: {
        latitude: 44.5672,
        longitude: -122.1269,
        zoom: 5.5,
    },
    Pennsylvania: {
        latitude: 40.5773,
        longitude: -77.264,
        zoom: 5.5,
    },
    'Rhode Island': {
        latitude: 41.6772,
        longitude: -71.5101,
        zoom: 5.5,
    },
    'South Carolina': {
        latitude: 33.8191,
        longitude: -80.9066,
        zoom: 5.5,
    },
    'South Dakota': {
        latitude: 44.2853,
        longitude: -99.4632,
        zoom: 5.5,
    },
    Tennessee: {
        latitude: 35.7449,
        longitude: -86.7489,
        zoom: 5.5,
    },
    Texas: {
        latitude: 31.106,
        longitude: -97.6475,
        zoom: 5.5,
    },
    Utah: {
        latitude: 40.1135,
        longitude: -111.8535,
        zoom: 5.5,
    },
    Virginia: {
        latitude: 37.768,
        longitude: -78.2057,
        zoom: 5.5,
    },
    Vermont: {
        latitude: 44.0407,
        longitude: -72.7093,
        zoom: 5.5,
    },
    Washington: {
        latitude: 47.3917,
        longitude: -121.5708,
        zoom: 5.5,
    },
    Wisconsin: {
        latitude: 44.2563,
        longitude: -89.6385,
        zoom: 5.5,
    },
    'West Virginia': {
        latitude: 38.468,
        longitude: -80.9696,
        zoom: 5.5,
    },
    Wyoming: {
        latitude: 42.7475,
        longitude: -107.2085,
        zoom: 5.5,
    },
    'District Of Columbia': {
        latitude: 38.8974,
        longitude: -77.0268,
        zoom: 5.5,
    },
    'Puerto Rico': {
        latitude: 18.2491,
        longitude: -66.628,
        zoom: 5.5,
    },
};

export const deCoords = {
    Berlin: {
        latitude: '52.5200',
        longitude: '13.4050',
        zoom: 5.5,
    },
    Stuttgart: {
        latitude: '48.7775',
        longitude: '9.1800',
        zoom: 5.5,
    },
    Munich: {
        latitude: '48.1375',
        longitude: '11.5750',
        zoom: 5.5,
    },
    Hamburg: {
        latitude: '53.5500',
        longitude: '10.0000',
        zoom: 5.5,
    },
    Cologne: {
        latitude: '50.9364',
        longitude: '6.9528',
        zoom: 5.5,
    },
    Frankfurt: {
        latitude: '50.1106',
        longitude: '8.6822',
        zoom: 5.5,
    },
    Düsseldorf: {
        latitude: '51.2333',
        longitude: '6.7833',
        zoom: 5.5,
    },
    Leipzig: {
        latitude: '51.3400',
        longitude: '12.3750',
        zoom: 5.5,
    },
    Dortmund: {
        latitude: '51.5167',
        longitude: '7.4667',
        zoom: 5.5,
    },
    Essen: {
        latitude: '51.4508',
        longitude: '7.0131',
        zoom: 5.5,
    },
    Bremen: {
        latitude: '53.0833',
        longitude: '8.8000',
        zoom: 5.5,
    },
    Dresden: {
        latitude: '51.0500',
        longitude: '13.7400',
        zoom: 5.5,
    },
    Hannover: {
        latitude: '52.3667',
        longitude: '9.7167',
        zoom: 5.5,
    },
    Nuremberg: {
        latitude: '49.4539',
        longitude: '11.0775',
        zoom: 5.5,
    },
    Duisburg: {
        latitude: '51.4347',
        longitude: '6.7625',
        zoom: 5.5,
    },
    Bochum: {
        latitude: '51.4819',
        longitude: '7.2158',
        zoom: 5.5,
    },
    Wuppertal: {
        latitude: '51.2667',
        longitude: '7.1833',
        zoom: 5.5,
    },
    Bielefeld: {
        latitude: '52.0211',
        longitude: '8.5347',
        zoom: 5.5,
    },
    Bonn: {
        latitude: '50.7333',
        longitude: '7.1000',
        zoom: 5.5,
    },
    Münster: {
        latitude: '51.9625',
        longitude: '7.6256',
        zoom: 5.5,
    },
    Mannheim: {
        latitude: '49.4878',
        longitude: '8.4661',
        zoom: 5.5,
    },
    Karlsruhe: {
        latitude: '49.0092',
        longitude: '8.4040',
        zoom: 5.5,
    },
    Augsburg: {
        latitude: '48.3689',
        longitude: '10.8978',
        zoom: 5.5,
    },
    Wiesbaden: {
        latitude: '50.0825',
        longitude: '8.2400',
        zoom: 5.5,
    },
    Mönchengladbach: {
        latitude: '51.2000',
        longitude: '6.4333',
        zoom: 5.5,
    },
    Gelsenkirchen: {
        latitude: '51.5167',
        longitude: '7.1000',
        zoom: 5.5,
    },
    Aachen: {
        latitude: '50.7756',
        longitude: '6.0836',
        zoom: 5.5,
    },
    Braunschweig: {
        latitude: '52.2667',
        longitude: '10.5167',
        zoom: 5.5,
    },
    Chemnitz: {
        latitude: '50.8333',
        longitude: '12.9167',
        zoom: 5.5,
    },
    Kiel: {
        latitude: '54.3233',
        longitude: '10.1394',
        zoom: 5.5,
    },
    Halle: {
        latitude: '51.4828',
        longitude: '11.9697',
        zoom: 5.5,
    },
    Magdeburg: {
        latitude: '52.1317',
        longitude: '11.6392',
        zoom: 5.5,
    },
    'Freiburg im Breisgau': {
        latitude: '47.9950',
        longitude: '7.8500',
        zoom: 5.5,
    },
    Krefeld: {
        latitude: '51.3333',
        longitude: '6.5667',
        zoom: 5.5,
    },
    Mainz: {
        latitude: '49.9833',
        longitude: '8.2667',
        zoom: 5.5,
    },
    Lübeck: {
        latitude: '53.8697',
        longitude: '10.6864',
        zoom: 5.5,
    },
    Erfurt: {
        latitude: '50.9833',
        longitude: '11.0333',
        zoom: 5.5,
    },
    Oberhausen: {
        latitude: '51.4967',
        longitude: '6.8706',
        zoom: 5.5,
    },
    Rostock: {
        latitude: '54.0833',
        longitude: '12.1333',
        zoom: 5.5,
    },
    Kassel: {
        latitude: '51.3158',
        longitude: '9.4979',
        zoom: 5.5,
    },
    Hagen: {
        latitude: '51.3667',
        longitude: '7.4833',
        zoom: 5.5,
    },
    Potsdam: {
        latitude: '52.4000',
        longitude: '13.0667',
        zoom: 5.5,
    },
    Saarbrücken: {
        latitude: '49.2333',
        longitude: '7.0000',
        zoom: 5.5,
    },
    Hamm: {
        latitude: '51.6833',
        longitude: '7.8167',
        zoom: 5.5,
    },
    Ludwigshafen: {
        latitude: '49.4811',
        longitude: '8.4353',
        zoom: 5.5,
    },
    Mülheim: {
        latitude: '51.4275',
        longitude: '6.8825',
        zoom: 5.5,
    },
    Oldenburg: {
        latitude: '53.1439',
        longitude: '8.2139',
        zoom: 5.5,
    },
    Osnabrück: {
        latitude: '52.2833',
        longitude: '8.0500',
        zoom: 5.5,
    },
    Leverkusen: {
        latitude: '51.0333',
        longitude: '6.9833',
        zoom: 5.5,
    },
    Darmstadt: {
        latitude: '49.8722',
        longitude: '8.6528',
        zoom: 5.5,
    },
    Solingen: {
        latitude: '51.1667',
        longitude: '7.0833',
        zoom: 5.5,
    },
    Heidelberg: {
        latitude: '49.4167',
        longitude: '8.7167',
        zoom: 5.5,
    },
    Herne: {
        latitude: '51.5500',
        longitude: '7.2167',
        zoom: 5.5,
    },
    Neuss: {
        latitude: '51.2000',
        longitude: '6.7000',
        zoom: 5.5,
    },
    Regensburg: {
        latitude: '49.0167',
        longitude: '12.0833',
        zoom: 5.5,
    },
    Paderborn: {
        latitude: '51.7181',
        longitude: '8.7542',
        zoom: 5.5,
    },
    Ingolstadt: {
        latitude: '48.7631',
        longitude: '11.4250',
        zoom: 5.5,
    },
    Fürth: {
        latitude: '49.4667',
        longitude: '11.0000',
        zoom: 5.5,
    },
    Würzburg: {
        latitude: '49.7833',
        longitude: '9.9333',
        zoom: 5.5,
    },
    Ulm: {
        latitude: '48.4000',
        longitude: '9.9833',
        zoom: 5.5,
    },
    Heilbronn: {
        latitude: '49.1500',
        longitude: '9.2167',
        zoom: 5.5,
    },
    Pforzheim: {
        latitude: '48.8950',
        longitude: '8.7050',
        zoom: 5.5,
    },
    Wolfsburg: {
        latitude: '52.4231',
        longitude: '10.7872',
        zoom: 5.5,
    },
    Göttingen: {
        latitude: '51.5339',
        longitude: '9.9356',
        zoom: 5.5,
    },
    Bottrop: {
        latitude: '51.5247',
        longitude: '6.9228',
        zoom: 5.5,
    },
    Reutlingen: {
        latitude: '48.4833',
        longitude: '9.2167',
        zoom: 5.5,
    },
    Koblenz: {
        latitude: '50.3597',
        longitude: '7.5978',
        zoom: 5.5,
    },
    Bremerhaven: {
        latitude: '53.5500',
        longitude: '8.5833',
        zoom: 5.5,
    },
    Erlangen: {
        latitude: '49.5833',
        longitude: '11.0167',
        zoom: 5.5,
    },
    Recklinghausen: {
        latitude: '51.5850',
        longitude: '7.1619',
        zoom: 5.5,
    },
    'Bergisch Gladbach': {
        latitude: '50.9918',
        longitude: '7.1303',
        zoom: 5.5,
    },
    Remscheid: {
        latitude: '51.1833',
        longitude: '7.2000',
        zoom: 5.5,
    },
    Jena: {
        latitude: '50.9272',
        longitude: '11.5861',
        zoom: 5.5,
    },
    Trier: {
        latitude: '49.7567',
        longitude: '6.6414',
        zoom: 5.5,
    },
    Salzgitter: {
        latitude: '52.1500',
        longitude: '10.3333',
        zoom: 5.5,
    },
    Moers: {
        latitude: '51.4592',
        longitude: '6.6197',
        zoom: 5.5,
    },
    Siegen: {
        latitude: '50.8833',
        longitude: '8.0167',
        zoom: 5.5,
    },
    Hildesheim: {
        latitude: '52.1500',
        longitude: '9.9500',
        zoom: 5.5,
    },
    Gütersloh: {
        latitude: '51.9000',
        longitude: '8.3833',
        zoom: 5.5,
    },
    Kaiserslautern: {
        latitude: '49.4447',
        longitude: '7.7689',
        zoom: 5.5,
    },
    Schwerin: {
        latitude: '53.6333',
        longitude: '11.4167',
        zoom: 5.5,
    },
    Esslingen: {
        latitude: '48.7333',
        longitude: '9.3167',
        zoom: 5.5,
    },
    Ludwigsburg: {
        latitude: '48.8975',
        longitude: '9.1922',
        zoom: 5.5,
    },
    Gladbeck: {
        latitude: '51.5667',
        longitude: '6.9667',
        zoom: 5.5,
    },
    Rosenheim: {
        latitude: '47.8500',
        longitude: '12.1333',
        zoom: 5.5,
    },
    Herten: {
        latitude: '51.6000',
        longitude: '7.1333',
        zoom: 5.5,
    },
    Hilden: {
        latitude: '51.1714',
        longitude: '6.9394',
        zoom: 5.5,
    },
    Elmshorn: {
        latitude: '53.7519',
        longitude: '9.6511',
        zoom: 5.5,
    },
    'Halle-Neustadt': {
        latitude: '51.4789',
        longitude: '11.9214',
        zoom: 5.5,
    },
    Pinneberg: {
        latitude: '53.6333',
        longitude: '9.8000',
        zoom: 5.5,
    },
    Siegburg: {
        latitude: '50.8014',
        longitude: '7.2044',
        zoom: 5.5,
    },
    Germering: {
        latitude: '48.1333',
        longitude: '11.3667',
        zoom: 5.5,
    },
    Ostfildern: {
        latitude: '48.7333',
        longitude: '9.2500',
        zoom: 5.5,
    },
    Kornwestheim: {
        latitude: '48.8598',
        longitude: '9.1852',
        zoom: 5.5,
    },
    Unterschleißheim: {
        latitude: '48.2833',
        longitude: '11.5667',
        zoom: 5.5,
    },
    Hattersheim: {
        latitude: '50.0722',
        longitude: '8.4864',
        zoom: 5.5,
    },
    Unterhaching: {
        latitude: '48.0658',
        longitude: '11.6100',
        zoom: 5.5,
    },
    Weingarten: {
        latitude: '47.8092',
        longitude: '9.6444',
        zoom: 5.5,
    },
    Obertshausen: {
        latitude: '50.0667',
        longitude: '8.8333',
        zoom: 5.5,
    },
    'Bad Soden am Taunus': {
        latitude: '50.1333',
        longitude: '8.5000',
        zoom: 5.5,
    },
    Haar: {
        latitude: '48.1000',
        longitude: '11.7333',
        zoom: 5.5,
    },
    Eschborn: {
        latitude: '50.1436',
        longitude: '8.5700',
        zoom: 5.5,
    },
    Ottobrunn: {
        latitude: '48.0667',
        longitude: '11.6667',
        zoom: 5.5,
    },
    Puchheim: {
        latitude: '48.1500',
        longitude: '11.3500',
        zoom: 5.5,
    },
    Gröbenzell: {
        latitude: '48.2000',
        longitude: '11.3667',
        zoom: 5.5,
    },
    Schenefeld: {
        latitude: '53.6028',
        longitude: '9.8233',
        zoom: 5.5,
    },
    Wolfratshausen: {
        latitude: '47.9133',
        longitude: '11.4278',
        zoom: 5.5,
    },
    Glinde: {
        latitude: '53.5406',
        longitude: '10.2111',
        zoom: 5.5,
    },
    Eilendorf: {
        latitude: '50.7794',
        longitude: '6.1625',
        zoom: 5.5,
    },
    Eppelheim: {
        latitude: '49.4000',
        longitude: '8.6333',
        zoom: 5.5,
    },
    Neubiberg: {
        latitude: '48.0833',
        longitude: '11.6833',
        zoom: 5.5,
    },
    Asperg: {
        latitude: '48.9000',
        longitude: '9.1333',
        zoom: 5.5,
    },
    'Wentorf bei Hamburg': {
        latitude: '53.4931',
        longitude: '10.2533',
        zoom: 5.5,
    },
    Kronshagen: {
        latitude: '54.3333',
        longitude: '10.0833',
        zoom: 5.5,
    },
    Eichenau: {
        latitude: '48.1667',
        longitude: '11.3167',
        zoom: 5.5,
    },
    'Steinbach am Taunus': {
        latitude: '50.1667',
        longitude: '8.5667',
        zoom: 5.5,
    },
    Weißenthurm: {
        latitude: '50.4144',
        longitude: '7.4606',
        zoom: 5.5,
    },
    Neulußheim: {
        latitude: '49.2933',
        longitude: '8.5219',
        zoom: 5.5,
    },
    Eichwalde: {
        latitude: '52.3730',
        longitude: '13.6213',
        zoom: 5.5,
    },
    Altbach: {
        latitude: '48.7239',
        longitude: '9.3797',
        zoom: 5.5,
    },
    Merzhausen: {
        latitude: '47.9664',
        longitude: '7.8286',
        zoom: 5.5,
    },
    Buckenhof: {
        latitude: '49.5914',
        longitude: '11.0522',
        zoom: 5.5,
    },
    Offenbach: {
        latitude: '50.1006',
        longitude: '8.7665',
        zoom: 5.5,
    },
};

export const esCoords = {
    Madrid: {
        latitude: '40.4169',
        longitude: '-3.7033',
        zoom: 5.5,
    },
    Barcelona: {
        latitude: '41.3825',
        longitude: '2.1769',
        zoom: 5.5,
    },
    Sevilla: {
        latitude: '37.2400',
        longitude: '-5.9900',
        zoom: 5.5,
    },
    Málaga: {
        latitude: '36.7194',
        longitude: '-4.4200',
        zoom: 5.5,
    },
    Valencia: {
        latitude: '39.4700',
        longitude: '-0.3764',
        zoom: 5.5,
    },
    Bilbao: {
        latitude: '43.2569',
        longitude: '-2.9236',
        zoom: 5.5,
    },
    Zaragoza: {
        latitude: '41.6500',
        longitude: '-0.8833',
        zoom: 5.5,
    },
    Murcia: {
        latitude: '37.9861',
        longitude: '-1.1303',
        zoom: 5.5,
    },
    Palma: {
        latitude: '39.5667',
        longitude: '2.6500',
        zoom: 5.5,
    },
    'Las Palmas': {
        latitude: '28.1272',
        longitude: '-15.4314',
        zoom: 5.5,
    },
    Alicante: {
        latitude: '38.3453',
        longitude: '-0.4831',
        zoom: 5.5,
    },
    Córdoba: {
        latitude: '37.8845',
        longitude: '-4.7796',
        zoom: 5.5,
    },
    Valladolid: {
        latitude: '41.6528',
        longitude: '-4.7236',
        zoom: 5.5,
    },
    Vigo: {
        latitude: '42.2314',
        longitude: '-8.7124',
        zoom: 5.5,
    },
    Gijón: {
        latitude: '43.5333',
        longitude: '-5.7000',
        zoom: 5.5,
    },
    'Vitoria-Gasteiz': {
        latitude: '42.8500',
        longitude: '-2.6833',
        zoom: 5.5,
    },
    'A Coruña': {
        latitude: '43.3667',
        longitude: '-8.3833',
        zoom: 5.5,
    },
    Elche: {
        latitude: '38.2669',
        longitude: '-0.6983',
        zoom: 5.5,
    },
    Granada: {
        latitude: '37.1781',
        longitude: '-3.6008',
        zoom: 5.5,
    },
    Tarrasa: {
        latitude: '41.5611',
        longitude: '2.0081',
        zoom: 5.5,
    },
    Badalona: {
        latitude: '41.4333',
        longitude: '2.2333',
        zoom: 5.5,
    },
    Petrel: {
        latitude: '38.4789',
        longitude: '-0.7967',
        zoom: 5.5,
    },
    Oviedo: {
        latitude: '43.3600',
        longitude: '-5.8450',
        zoom: 5.5,
    },
    Cartagena: {
        latitude: '37.6000',
        longitude: '-0.9819',
        zoom: 5.5,
    },
    Sabadell: {
        latitude: '41.5483',
        longitude: '2.1075',
        zoom: 5.5,
    },
    'Jerez de la Frontera': {
        latitude: '36.6817',
        longitude: '-6.1378',
        zoom: 5.5,
    },
    Móstoles: {
        latitude: '40.3333',
        longitude: '-3.8667',
        zoom: 5.5,
    },
    'Santa Cruz': {
        latitude: '28.4667',
        longitude: '-16.2500',
        zoom: 5.5,
    },
    Pamplona: {
        latitude: '42.8167',
        longitude: '-1.6500',
        zoom: 5.5,
    },
    Almería: {
        latitude: '36.8403',
        longitude: '-2.4681',
        zoom: 5.5,
    },
    'Alcalá de Henares': {
        latitude: '40.4818',
        longitude: '-3.3643',
        zoom: 5.5,
    },
    Fuenlabrada: {
        latitude: '40.2833',
        longitude: '-3.8000',
        zoom: 5.5,
    },
    Donostia: {
        latitude: '43.3200',
        longitude: '-1.9800',
        zoom: 5.5,
    },
    Leganés: {
        latitude: '40.3281',
        longitude: '-3.7644',
        zoom: 5.5,
    },
    Getafe: {
        latitude: '40.3047',
        longitude: '-3.7311',
        zoom: 5.5,
    },
    Burgos: {
        latitude: '42.3408',
        longitude: '-3.6997',
        zoom: 5.5,
    },
    Albacete: {
        latitude: '38.9956',
        longitude: '-1.8558',
        zoom: 5.5,
    },
    'Castellón de la Plana': {
        latitude: '39.9831',
        longitude: '-0.0331',
        zoom: 5.5,
    },
    Santander: {
        latitude: '43.4628',
        longitude: '-3.8050',
        zoom: 5.5,
    },
    Alcorcón: {
        latitude: '40.3500',
        longitude: '-3.8333',
        zoom: 5.5,
    },
    Guadalajara: {
        latitude: '40.6337',
        longitude: '-3.1674',
        zoom: 5.5,
    },
    'La Laguna': {
        latitude: '28.4853',
        longitude: '-16.3167',
        zoom: 5.5,
    },
    Logroño: {
        latitude: '42.4650',
        longitude: '-2.4456',
        zoom: 5.5,
    },
    Marbella: {
        latitude: '36.5167',
        longitude: '-4.8833',
        zoom: 5.5,
    },
    Badajoz: {
        latitude: '38.8803',
        longitude: '-6.9753',
        zoom: 5.5,
    },
    Salamanca: {
        latitude: '40.9650',
        longitude: '-5.6642',
        zoom: 5.5,
    },
    Huelva: {
        latitude: '37.2500',
        longitude: '-6.9500',
        zoom: 5.5,
    },
    Lleida: {
        latitude: '41.6167',
        longitude: '0.6333',
        zoom: 5.5,
    },
    'Dos Hermanas': {
        latitude: '37.2836',
        longitude: '-5.9222',
        zoom: 5.5,
    },
    Tarragona: {
        latitude: '41.1187',
        longitude: '1.2453',
        zoom: 5.5,
    },
    'Torrejón de Ardoz': {
        latitude: '40.4614',
        longitude: '-3.4978',
        zoom: 5.5,
    },
    Parla: {
        latitude: '40.2372',
        longitude: '-3.7742',
        zoom: 5.5,
    },
    Mataró: {
        latitude: '41.5333',
        longitude: '2.4500',
        zoom: 5.5,
    },
    Algeciras: {
        latitude: '36.1275',
        longitude: '-5.4539',
        zoom: 5.5,
    },
    León: {
        latitude: '42.6056',
        longitude: '-5.5700',
        zoom: 5.5,
    },
    Alcobendas: {
        latitude: '40.5333',
        longitude: '-3.6333',
        zoom: 5.5,
    },
    Cádiz: {
        latitude: '36.5350',
        longitude: '-6.2975',
        zoom: 5.5,
    },
    Jaén: {
        latitude: '37.7667',
        longitude: '-3.7711',
        zoom: 5.5,
    },
    Reus: {
        latitude: '41.1549',
        longitude: '1.1087',
        zoom: 5.5,
    },
    Ourense: {
        latitude: '42.3364',
        longitude: '-7.8633',
        zoom: 5.5,
    },
    'Roquetas de Mar': {
        latitude: '36.7642',
        longitude: '-2.6147',
        zoom: 5.5,
    },
    Telde: {
        latitude: '27.9985',
        longitude: '-15.4167',
        zoom: 5.5,
    },
    Girona: {
        latitude: '41.9833',
        longitude: '2.8167',
        zoom: 5.5,
    },
    'San Vicente de Baracaldo': {
        latitude: '43.2972',
        longitude: '-2.9917',
        zoom: 5.5,
    },
    'Santiago de Compostela': {
        latitude: '42.8778',
        longitude: '-8.5444',
        zoom: 5.5,
    },
    'San Cugat del Vallés': {
        latitude: '41.4735',
        longitude: '2.0852',
        zoom: 5.5,
    },
    'Las Rozas de Madrid': {
        latitude: '40.4917',
        longitude: '-3.8733',
        zoom: 5.5,
    },
    'San Fernando': {
        latitude: '36.4667',
        longitude: '-6.2000',
        zoom: 5.5,
    },
    'San Sebastián de los Reyes': {
        latitude: '40.5469',
        longitude: '-3.6258',
        zoom: 5.5,
    },
    'Ciudad de Melilla': {
        latitude: '35.2825',
        longitude: '-2.9475',
        zoom: 5.5,
    },
    Toledo: {
        latitude: '39.8567',
        longitude: '-4.0244',
        zoom: 5.5,
    },
    Torrente: {
        latitude: '39.4365',
        longitude: '-0.4679',
        zoom: 5.5,
    },
    'San Baudilio de Llobregat': {
        latitude: '41.3458',
        longitude: '2.0417',
        zoom: 5.5,
    },
    Torrevieja: {
        latitude: '37.9778',
        longitude: '-0.6833',
        zoom: 5.5,
    },
    'Ciudad de Ceuta': {
        latitude: '35.8867',
        longitude: '-5.3000',
        zoom: 5.5,
    },
    Fuengirola: {
        latitude: '36.5417',
        longitude: '-4.6250',
        zoom: 5.5,
    },
    Arona: {
        latitude: '28.0996',
        longitude: '-16.6809',
        zoom: 5.5,
    },
    Valdemoro: {
        latitude: '40.1908',
        longitude: '-3.6742',
        zoom: 5.5,
    },
    Rubí: {
        latitude: '41.4933',
        longitude: '2.0325',
        zoom: 5.5,
    },
    Manresa: {
        latitude: '41.7264',
        longitude: '1.8292',
        zoom: 5.5,
    },
    Avilés: {
        latitude: '43.5561',
        longitude: '-5.9083',
        zoom: 5.5,
    },
    Gandía: {
        latitude: '38.9667',
        longitude: '-0.1833',
        zoom: 5.5,
    },
    Benalmádena: {
        latitude: '36.6000',
        longitude: '-4.5167',
        zoom: 5.5,
    },
    Majadahonda: {
        latitude: '40.4728',
        longitude: '-3.8722',
        zoom: 5.5,
    },
    Paterna: {
        latitude: '39.5028',
        longitude: '-0.4406',
        zoom: 5.5,
    },
    Benidorm: {
        latitude: '38.5342',
        longitude: '-0.1314',
        zoom: 5.5,
    },
    Torremolinos: {
        latitude: '36.6218',
        longitude: '-4.5003',
        zoom: 5.5,
    },
    'Villanueva y Geltrú': {
        latitude: '41.2243',
        longitude: '1.7259',
        zoom: 5.5,
    },
    Castelldefels: {
        latitude: '41.2800',
        longitude: '1.9767',
        zoom: 5.5,
    },
    Viladecáns: {
        latitude: '41.3158',
        longitude: '2.0194',
        zoom: 5.5,
    },
    'El Prat de Llobregat': {
        latitude: '41.3246',
        longitude: '2.0953',
        zoom: 5.5,
    },
    Arrecife: {
        latitude: '28.9625',
        longitude: '-13.5506',
        zoom: 5.5,
    },
    'Collado-Villalba': {
        latitude: '40.6333',
        longitude: '-4.0083',
        zoom: 5.5,
    },
    'La Línea de la Concepción': {
        latitude: '36.1611',
        longitude: '-5.3486',
        zoom: 5.5,
    },
    Irún: {
        latitude: '43.3378',
        longitude: '-1.7888',
        zoom: 5.5,
    },
    'Boadilla del Monte': {
        latitude: '40.4069',
        longitude: '-3.8750',
        zoom: 5.5,
    },
    Granollers: {
        latitude: '41.6083',
        longitude: '2.2889',
        zoom: 5.5,
    },
    Mérida: {
        latitude: '38.9000',
        longitude: '-6.3333',
        zoom: 5.5,
    },
    'San Vicente del Raspeig': {
        latitude: '38.3964',
        longitude: '-0.5253',
        zoom: 5.5,
    },
    Elda: {
        latitude: '38.4789',
        longitude: '-0.7967',
        zoom: 5.5,
    },
    Mollet: {
        latitude: '41.5356',
        longitude: '2.2107',
        zoom: 5.5,
    },
    Torrelavega: {
        latitude: '43.3531',
        longitude: '-4.0458',
        zoom: 5.5,
    },
    Ibiza: {
        latitude: '38.9089',
        longitude: '1.4328',
        zoom: 5.5,
    },
    'Rincón de la Victoria': {
        latitude: '36.7167',
        longitude: '-4.2833',
        zoom: 5.5,
    },
    Vich: {
        latitude: '41.9304',
        longitude: '2.2546',
        zoom: 5.5,
    },
    'Mairena del Aljarafe': {
        latitude: '37.3333',
        longitude: '-6.0667',
        zoom: 5.5,
    },
    Figueras: {
        latitude: '42.2667',
        longitude: '2.9650',
        zoom: 5.5,
    },
    Gavá: {
        latitude: '41.3072',
        longitude: '2.0039',
        zoom: 5.5,
    },
    'Esplugas de Llobregat': {
        latitude: '41.3767',
        longitude: '2.0858',
        zoom: 5.5,
    },
    'Santurce-Antiguo': {
        latitude: '43.3303',
        longitude: '-3.0314',
        zoom: 5.5,
    },
    'San Felíu de Llobregat': {
        latitude: '41.3833',
        longitude: '2.0439',
        zoom: 5.5,
    },
    Portugalete: {
        latitude: '43.3194',
        longitude: '-3.0194',
        zoom: 5.5,
    },
    Mislata: {
        latitude: '39.4750',
        longitude: '-0.4156',
        zoom: 5.5,
    },
    Alcantarilla: {
        latitude: '37.9722',
        longitude: '-1.2094',
        zoom: 5.5,
    },
    Igualada: {
        latitude: '41.5814',
        longitude: '1.6208',
        zoom: 5.5,
    },
    Blanes: {
        latitude: '41.6740',
        longitude: '2.7921',
        zoom: 5.5,
    },
    'Villafranca del Panadés': {
        latitude: '41.3447',
        longitude: '1.6994',
        zoom: 5.5,
    },
    'San Fernando de Henares': {
        latitude: '40.4256',
        longitude: '-3.5353',
        zoom: 5.5,
    },
    Vendrell: {
        latitude: '41.2201',
        longitude: '1.5348',
        zoom: 5.5,
    },
    Burjasot: {
        latitude: '39.5064',
        longitude: '-0.4067',
        zoom: 5.5,
    },
    Arucas: {
        latitude: '28.1184',
        longitude: '-15.5232',
        zoom: 5.5,
    },
    Ripollet: {
        latitude: '41.4969',
        longitude: '2.1574',
        zoom: 5.5,
    },
    'San Adrián de Besós': {
        latitude: '41.4305',
        longitude: '2.2183',
        zoom: 5.5,
    },
    Moncada: {
        latitude: '39.5456',
        longitude: '-0.3956',
        zoom: 5.5,
    },
    Olot: {
        latitude: '42.1822',
        longitude: '2.4890',
        zoom: 5.5,
    },
    Cambrils: {
        latitude: '41.0670',
        longitude: '1.0564',
        zoom: 5.5,
    },
    'Azuqueca de Henares': {
        latitude: '40.5647',
        longitude: '-3.2681',
        zoom: 5.5,
    },
    Arroyomolinos: {
        latitude: '40.2667',
        longitude: '-3.9000',
        zoom: 5.5,
    },
    Villajoyosa: {
        latitude: '38.5053',
        longitude: '-0.2328',
        zoom: 5.5,
    },
    'San Juan Despí': {
        latitude: '41.3668',
        longitude: '2.0570',
        zoom: 5.5,
    },
    Aldaya: {
        latitude: '39.4639',
        longitude: '-0.4628',
        zoom: 5.5,
    },
    Salt: {
        latitude: '41.9761',
        longitude: '2.7881',
        zoom: 5.5,
    },
    Manises: {
        latitude: '39.4833',
        longitude: '-0.4500',
        zoom: 5.5,
    },
    Durango: {
        latitude: '43.1689',
        longitude: '-2.6300',
        zoom: 5.5,
    },
    Alacuás: {
        latitude: '39.4583',
        longitude: '-0.4628',
        zoom: 5.5,
    },
    Calafell: {
        latitude: '41.2004',
        longitude: '1.5693',
        zoom: 5.5,
    },
    Salou: {
        latitude: '41.0796',
        longitude: '1.1316',
        zoom: 5.5,
    },
    Martorell: {
        latitude: '41.4744',
        longitude: '1.9305',
        zoom: 5.5,
    },
    Catarroja: {
        latitude: '39.4028',
        longitude: '-0.4044',
        zoom: 5.5,
    },
    'Premiá de Mar': {
        latitude: '41.4920',
        longitude: '2.3620',
        zoom: 5.5,
    },
    'San Vicente dels Horts': {
        latitude: '41.3932',
        longitude: '2.0097',
        zoom: 5.5,
    },
    Sestao: {
        latitude: '43.3108',
        longitude: '-3.0056',
        zoom: 5.5,
    },
    Camas: {
        latitude: '37.4020',
        longitude: '-6.0332',
        zoom: 5.5,
    },
    'San Andrés de la Barca': {
        latitude: '41.4478',
        longitude: '1.9769',
        zoom: 5.5,
    },
    'San Pedro del Pinatar': {
        latitude: '37.8167',
        longitude: '-0.7500',
        zoom: 5.5,
    },
    Paiporta: {
        latitude: '39.4278',
        longitude: '-0.4183',
        zoom: 5.5,
    },
    'Molíns de Rey': {
        latitude: '41.4139',
        longitude: '2.0158',
        zoom: 5.5,
    },
    'Santa Perpetua de Moguda': {
        latitude: '41.5375',
        longitude: '2.1819',
        zoom: 5.5,
    },
    Tomares: {
        latitude: '37.3764',
        longitude: '-6.0458',
        zoom: 5.5,
    },
    Alboraya: {
        latitude: '39.5000',
        longitude: '-0.3522',
        zoom: 5.5,
    },
    Torrelodones: {
        latitude: '40.5756',
        longitude: '-3.9292',
        zoom: 5.5,
    },
    Armilla: {
        latitude: '37.1500',
        longitude: '-3.6167',
        zoom: 5.5,
    },
    Erandio: {
        latitude: '43.3047',
        longitude: '-2.9731',
        zoom: 5.5,
    },
    'San Juan de Alicante': {
        latitude: '38.4014',
        longitude: '-0.4367',
        zoom: 5.5,
    },
    'Olesa de Montserrat': {
        latitude: '41.5450',
        longitude: '1.8944',
        zoom: 5.5,
    },
    Calpe: {
        latitude: '38.6450',
        longitude: '0.0442',
        zoom: 5.5,
    },
    'Mejorada del Campo': {
        latitude: '40.3967',
        longitude: '-3.3858',
        zoom: 5.5,
    },
    Zarautz: {
        latitude: '43.2833',
        longitude: '-2.1667',
        zoom: 5.5,
    },
    'Vilaseca de Solcina': {
        latitude: '41.1110',
        longitude: '1.1450',
        zoom: 5.5,
    },
    Bormujos: {
        latitude: '37.3667',
        longitude: '-6.0667',
        zoom: 5.5,
    },
    Maracena: {
        latitude: '37.2000',
        longitude: '-3.6333',
        zoom: 5.5,
    },
    'San Felíu de Guixols': {
        latitude: '41.7806',
        longitude: '3.0306',
        zoom: 5.5,
    },
    'San Juan de Aznalfarache': {
        latitude: '37.3667',
        longitude: '-6.0167',
        zoom: 5.5,
    },
    Alfafar: {
        latitude: '39.4222',
        longitude: '-0.3906',
        zoom: 5.5,
    },
    'San Juan de Vilasar': {
        latitude: '41.5053',
        longitude: '2.3928',
        zoom: 5.5,
    },
    Palafrugell: {
        latitude: '41.9174',
        longitude: '3.1631',
        zoom: 5.5,
    },
    Manlleu: {
        latitude: '42.0000',
        longitude: '2.2836',
        zoom: 5.5,
    },
    Puzol: {
        latitude: '39.6167',
        longitude: '-0.3011',
        zoom: 5.5,
    },
    Bañolas: {
        latitude: '42.1194',
        longitude: '2.7664',
        zoom: 5.5,
    },
    Barañáin: {
        latitude: '42.8000',
        longitude: '-1.6667',
        zoom: 5.5,
    },
    'Humanes de Madrid': {
        latitude: '40.2539',
        longitude: '-3.8278',
        zoom: 5.5,
    },
    'Sant Just Desvern': {
        latitude: '41.3833',
        longitude: '2.0750',
        zoom: 5.5,
    },
    Archena: {
        latitude: '38.1150',
        longitude: '-1.2992',
        zoom: 5.5,
    },
    Calella: {
        latitude: '41.6169',
        longitude: '2.6642',
        zoom: 5.5,
    },
    'La Corredoría': {
        latitude: '43.3853',
        longitude: '-5.8208',
        zoom: 5.5,
    },
    'Malgrat de Mar': {
        latitude: '41.6456',
        longitude: '2.7428',
        zoom: 5.5,
    },
    Utebo: {
        latitude: '41.7141',
        longitude: '-0.9944',
        zoom: 5.5,
    },
    Cardedeu: {
        latitude: '41.6406',
        longitude: '2.3594',
        zoom: 5.5,
    },
    Palamós: {
        latitude: '41.8458',
        longitude: '3.1289',
        zoom: 5.5,
    },
    'El Astillero': {
        latitude: '43.4017',
        longitude: '-3.8194',
        zoom: 5.5,
    },
    'Castilleja de la Cuesta': {
        latitude: '37.3833',
        longitude: '-6.0500',
        zoom: 5.5,
    },
    Torredembarra: {
        latitude: '41.1457',
        longitude: '1.3957',
        zoom: 5.5,
    },
    'Guernica y Luno': {
        latitude: '43.3167',
        longitude: '-2.6667',
        zoom: 5.5,
    },
    Canovellas: {
        latitude: '41.6176',
        longitude: '2.2814',
        zoom: 5.5,
    },
    Albal: {
        latitude: '39.3972',
        longitude: '-0.4156',
        zoom: 5.5,
    },
    'Montornés del Vallés': {
        latitude: '41.5444',
        longitude: '2.2670',
        zoom: 5.5,
    },
    Cubellas: {
        latitude: '41.2100',
        longitude: '1.6736',
        zoom: 5.5,
    },
    Masamagrell: {
        latitude: '39.5703',
        longitude: '-0.3300',
        zoom: 5.5,
    },
    'Arenys de Mar': {
        latitude: '41.5819',
        longitude: '2.5503',
        zoom: 5.5,
    },
    'Churriana de la Vega': {
        latitude: '37.1500',
        longitude: '-3.6500',
        zoom: 5.5,
    },
    Ermúa: {
        latitude: '43.1875',
        longitude: '-2.5008',
        zoom: 5.5,
    },
    Benetúser: {
        latitude: '39.4250',
        longitude: '-0.3961',
        zoom: 5.5,
    },
    Alpedrete: {
        latitude: '40.6583',
        longitude: '-4.0322',
        zoom: 5.5,
    },
    'Canet de Mar': {
        latitude: '41.5911',
        longitude: '2.5828',
        zoom: 5.5,
    },
    Mollerusa: {
        latitude: '41.6317',
        longitude: '0.8961',
        zoom: 5.5,
    },
    Torelló: {
        latitude: '42.0492',
        longitude: '2.2629',
        zoom: 5.5,
    },
    'Santa Marta de Tormes': {
        latitude: '40.9494',
        longitude: '-5.6325',
        zoom: 5.5,
    },
    Sopelana: {
        latitude: '43.3814',
        longitude: '-2.9792',
        zoom: 5.5,
    },
    Ogíjares: {
        latitude: '37.1167',
        longitude: '-3.6000',
        zoom: 5.5,
    },
    Cunit: {
        latitude: '41.1976',
        longitude: '1.6345',
        zoom: 5.5,
    },
    'Cuarte de Huerva': {
        latitude: '41.5833',
        longitude: '-0.9167',
        zoom: 5.5,
    },
    Gines: {
        latitude: '37.3875',
        longitude: '-6.0781',
        zoom: 5.5,
    },
    'La Llagosta': {
        latitude: '41.5156',
        longitude: '2.1928',
        zoom: 5.5,
    },
    Godella: {
        latitude: '39.5200',
        longitude: '-0.4114',
        zoom: 5.5,
    },
    'Vilanova del Camí': {
        latitude: '41.5733',
        longitude: '1.6381',
        zoom: 5.5,
    },
    Ceuti: {
        latitude: '38.0789',
        longitude: '-1.2722',
        zoom: 5.5,
    },
    Mongat: {
        latitude: '41.4667',
        longitude: '2.2790',
        zoom: 5.5,
    },
    'Huétor Vega': {
        latitude: '37.1500',
        longitude: '-3.5667',
        zoom: 5.5,
    },
    Picaña: {
        latitude: '39.4361',
        longitude: '-0.4350',
        zoom: 5.5,
    },
    Pallejá: {
        latitude: '41.4242',
        longitude: '1.9978',
        zoom: 5.5,
    },
    Peligros: {
        latitude: '37.2333',
        longitude: '-3.6333',
        zoom: 5.5,
    },
    'San Andrés de Llevaneras': {
        latitude: '41.5733',
        longitude: '2.4828',
        zoom: 5.5,
    },
    Beniel: {
        latitude: '38.0464',
        longitude: '-1.0014',
        zoom: 5.5,
    },
    Meliana: {
        latitude: '39.5272',
        longitude: '-0.3492',
        zoom: 5.5,
    },
    Berriozar: {
        latitude: '42.8361',
        longitude: '-1.6714',
        zoom: 5.5,
    },
    Sedaví: {
        latitude: '39.4271',
        longitude: '-0.3849',
        zoom: 5.5,
    },
    Gelves: {
        latitude: '37.3333',
        longitude: '-6.0167',
        zoom: 5.5,
    },
    Alella: {
        latitude: '41.4953',
        longitude: '2.2958',
        zoom: 5.5,
    },
    Garrucha: {
        latitude: '37.1842',
        longitude: '-1.8214',
        zoom: 5.5,
    },
    'Torrejón de la Calzada': {
        latitude: '40.2000',
        longitude: '-3.8000',
        zoom: 5.5,
    },
    Masanasa: {
        latitude: '39.4083',
        longitude: '-0.3989',
        zoom: 5.5,
    },
    'Tabernes Blanques': {
        latitude: '39.5064',
        longitude: '-0.3626',
        zoom: 5.5,
    },
    Tiana: {
        latitude: '41.4831',
        longitude: '2.2697',
        zoom: 5.5,
    },
    Reinosa: {
        latitude: '43.0019',
        longitude: '-4.1378',
        zoom: 5.5,
    },
    Montmeló: {
        latitude: '41.5547',
        longitude: '2.2500',
        zoom: 5.5,
    },
    Colindres: {
        latitude: '43.3967',
        longitude: '-3.4483',
        zoom: 5.5,
    },
    Santiponce: {
        latitude: '37.4353',
        longitude: '-6.0433',
        zoom: 5.5,
    },
    Ortuella: {
        latitude: '43.3103',
        longitude: '-3.0569',
        zoom: 5.5,
    },
    Ondárroa: {
        latitude: '43.3219',
        longitude: '-2.4194',
        zoom: 5.5,
    },
    'Santa Coloma de Cervelló': {
        latitude: '41.3687',
        longitude: '2.0175',
        zoom: 5.5,
    },
    Cabrils: {
        latitude: '41.5283',
        longitude: '2.3692',
        zoom: 5.5,
    },
    'Cúllar-Vega': {
        latitude: '37.1531',
        longitude: '-3.6706',
        zoom: 5.5,
    },
    Rocafort: {
        latitude: '39.5306',
        longitude: '-0.4100',
        zoom: 5.5,
    },
    Foyos: {
        latitude: '39.5386',
        longitude: '-0.3567',
        zoom: 5.5,
    },
    Almácera: {
        latitude: '39.5117',
        longitude: '-0.3561',
        zoom: 5.5,
    },
    Lekeitio: {
        latitude: '43.3622',
        longitude: '-2.4961',
        zoom: 5.5,
    },
    Bigastro: {
        latitude: '38.0631',
        longitude: '-0.8956',
        zoom: 5.5,
    },
    'Canet de Berenguer': {
        latitude: '39.6794',
        longitude: '-0.2206',
        zoom: 5.5,
    },
    'Villanueva del Ariscal': {
        latitude: '37.3833',
        longitude: '-6.1333',
        zoom: 5.5,
    },
    Teyá: {
        latitude: '41.4986',
        longitude: '2.3242',
        zoom: 5.5,
    },
    'Roda del Ter': {
        latitude: '41.9801',
        longitude: '2.3091',
        zoom: 5.5,
    },
    Nazaret: {
        latitude: '39.4481',
        longitude: '-0.3335',
        zoom: 5.5,
    },
    Navarcles: {
        latitude: '41.7532',
        longitude: '1.9034',
        zoom: 5.5,
    },
    Cájar: {
        latitude: '37.1333',
        longitude: '-3.5667',
        zoom: 5.5,
    },
    Capellades: {
        latitude: '41.5319',
        longitude: '1.6867',
        zoom: 5.5,
    },
    'Sarriá de Ter': {
        latitude: '42.0181',
        longitude: '2.8261',
        zoom: 5.5,
    },
    Bellreguart: {
        latitude: '38.9464',
        longitude: '-0.1625',
        zoom: 5.5,
    },
    Rafal: {
        latitude: '38.1047',
        longitude: '-0.8492',
        zoom: 5.5,
    },
    'Formentera de Segura': {
        latitude: '38.0833',
        longitude: '-0.7500',
        zoom: 5.5,
    },
    Hostalrich: {
        latitude: '41.7481',
        longitude: '2.6361',
        zoom: 5.5,
    },
    Jun: {
        latitude: '37.2167',
        longitude: '-3.6000',
        zoom: 5.5,
    },
    'Bonrepós y Mirambell': {
        latitude: '39.5206',
        longitude: '-0.3650',
        zoom: 5.5,
    },
    'San Hipólito de Voltregá': {
        latitude: '42.0172',
        longitude: '2.2389',
        zoom: 5.5,
    },
    Vinalesa: {
        latitude: '39.5406',
        longitude: '-0.3700',
        zoom: 5.5,
    },
    'Alfara del Patriarca': {
        latitude: '39.5436',
        longitude: '-0.3850',
        zoom: 5.5,
    },
    'Caldas de Estrach': {
        latitude: '41.5719',
        longitude: '2.5286',
        zoom: 5.5,
    },
    'Fuente de San Luis': {
        latitude: '39.4442',
        longitude: '-0.3675',
        zoom: 5.5,
    },
    'Castilleja de Guzmán': {
        latitude: '37.4000',
        longitude: '-6.0500',
        zoom: 5.5,
    },
    Masalfasar: {
        latitude: '39.5611',
        longitude: '-0.3250',
        zoom: 5.5,
    },
    Almoines: {
        latitude: '38.9433',
        longitude: '-0.1822',
        zoom: 5.5,
    },
    Benisanó: {
        latitude: '39.6133',
        longitude: '-0.5753',
        zoom: 5.5,
    },
    Benirredrá: {
        latitude: '38.9611',
        longitude: '-0.1919',
        zoom: 5.5,
    },
    'El Puente del Arzobispo': {
        latitude: '39.8000',
        longitude: '-5.1667',
        zoom: 5.5,
    },
    Palmera: {
        latitude: '38.9417',
        longitude: '-0.1517',
        zoom: 5.5,
    },
    Hortaleza: {
        latitude: '40.4667',
        longitude: '-3.6333',
        zoom: 5.5,
    },
};

export const chCoords = {
    Guangzhou: {
        latitude: '23.1300',
        longitude: '113.2600',
        zoom: 5.5,
    },
    Shanghai: {
        latitude: '31.1667',
        longitude: '121.4667',
        zoom: 5.5,
    },
    Beijing: {
        latitude: '39.9040',
        longitude: '116.4075',
        zoom: 5.5,
    },
    Shenzhen: {
        latitude: '22.5350',
        longitude: '114.0540',
        zoom: 5.5,
    },
    Chengdu: {
        latitude: '30.6600',
        longitude: '104.0633',
        zoom: 5.5,
    },
    'Xi’an': {
        latitude: '34.2667',
        longitude: '108.9000',
        zoom: 5.5,
    },
    Chongqing: {
        latitude: '29.5500',
        longitude: '106.5069',
        zoom: 5.5,
    },
    Baoding: {
        latitude: '38.8671',
        longitude: '115.4845',
        zoom: 5.5,
    },
    Linyi: {
        latitude: '35.1041',
        longitude: '118.3502',
        zoom: 5.5,
    },
    Dongguan: {
        latitude: '23.0475',
        longitude: '113.7493',
        zoom: 5.5,
    },
    Tianjin: {
        latitude: '39.1467',
        longitude: '117.2056',
        zoom: 5.5,
    },
    Wuhan: {
        latitude: '30.5872',
        longitude: '114.2881',
        zoom: 5.5,
    },
    Nanyang: {
        latitude: '32.9987',
        longitude: '112.5292',
        zoom: 5.5,
    },
    Hangzhou: {
        latitude: '30.2500',
        longitude: '120.1675',
        zoom: 5.5,
    },
    Foshan: {
        latitude: '23.0292',
        longitude: '113.1056',
        zoom: 5.5,
    },
    Tongshan: {
        latitude: '34.2610',
        longitude: '117.1859',
        zoom: 5.5,
    },
    Zhoukou: {
        latitude: '33.6250',
        longitude: '114.6418',
        zoom: 5.5,
    },
    Ganzhou: {
        latitude: '25.8292',
        longitude: '114.9336',
        zoom: 5.5,
    },
    Heze: {
        latitude: '35.2333',
        longitude: '115.4333',
        zoom: 5.5,
    },
    Quanzhou: {
        latitude: '24.9139',
        longitude: '118.5858',
        zoom: 5.5,
    },
    Nanjing: {
        latitude: '32.0608',
        longitude: '118.7789',
        zoom: 5.5,
    },
    Jining: {
        latitude: '35.4000',
        longitude: '116.5667',
        zoom: 5.5,
    },
    Fuyang: {
        latitude: '32.8986',
        longitude: '115.8045',
        zoom: 5.5,
    },
    Shenyang: {
        latitude: '41.8025',
        longitude: '123.4281',
        zoom: 5.5,
    },
    Shangqiu: {
        latitude: '34.4259',
        longitude: '115.6467',
        zoom: 5.5,
    },
    Cangzhou: {
        latitude: '38.3037',
        longitude: '116.8452',
        zoom: 5.5,
    },
    Shaoyang: {
        latitude: '32.9387',
        longitude: '119.8404',
        zoom: 5.5,
    },
    Zhanjiang: {
        latitude: '21.1967',
        longitude: '110.4031',
        zoom: 5.5,
    },
    Yancheng: {
        latitude: '33.3936',
        longitude: '120.1339',
        zoom: 5.5,
    },
    Hengyang: {
        latitude: '26.8968',
        longitude: '112.5857',
        zoom: 5.5,
    },
    Zhumadian: {
        latitude: '32.9773',
        longitude: '114.0253',
        zoom: 5.5,
    },
    Xingtai: {
        latitude: '37.0659',
        longitude: '114.4753',
        zoom: 5.5,
    },
    Bijie: {
        latitude: '27.3019',
        longitude: '105.2863',
        zoom: 5.5,
    },
    Shangrao: {
        latitude: '28.4419',
        longitude: '117.9633',
        zoom: 5.5,
    },
    Zunyi: {
        latitude: '27.7050',
        longitude: '106.9336',
        zoom: 5.5,
    },
    Huanggang: {
        latitude: '30.4500',
        longitude: '114.8750',
        zoom: 5.5,
    },
    Maoming: {
        latitude: '21.6618',
        longitude: '110.9178',
        zoom: 5.5,
    },
    Nanchong: {
        latitude: '30.7991',
        longitude: '106.0784',
        zoom: 5.5,
    },
    Xinyang: {
        latitude: '32.1264',
        longitude: '114.0672',
        zoom: 5.5,
    },
    Qujing: {
        latitude: '25.5102',
        longitude: '103.8029',
        zoom: 5.5,
    },
    Jieyang: {
        latitude: '23.5533',
        longitude: '116.3649',
        zoom: 5.5,
    },
    Liaocheng: {
        latitude: '36.4500',
        longitude: '115.9833',
        zoom: 5.5,
    },
    Dalian: {
        latitude: '38.9000',
        longitude: '121.6000',
        zoom: 5.5,
    },
    Yulin: {
        latitude: '22.6293',
        longitude: '110.1507',
        zoom: 5.5,
    },
    Changde: {
        latitude: '29.0397',
        longitude: '111.6839',
        zoom: 5.5,
    },
    Qingdao: {
        latitude: '36.1167',
        longitude: '120.4000',
        zoom: 5.5,
    },
    Nangandao: {
        latitude: '35.2992',
        longitude: '113.8851',
        zoom: 5.5,
    },
    Pudong: {
        latitude: '31.2231',
        longitude: '121.5397',
        zoom: 5.5,
    },
    Xiangyang: {
        latitude: '32.0654',
        longitude: '112.1531',
        zoom: 5.5,
    },
    Zhengzhou: {
        latitude: '34.7492',
        longitude: '113.6605',
        zoom: 5.5,
    },
    'Lu’an': {
        latitude: '31.7542',
        longitude: '116.5078',
        zoom: 5.5,
    },
    Dezhou: {
        latitude: '37.4513',
        longitude: '116.3105',
        zoom: 5.5,
    },
    Jinan: {
        latitude: '36.6667',
        longitude: '116.9833',
        zoom: 5.5,
    },
    Zhaotong: {
        latitude: '27.3328',
        longitude: '103.7144',
        zoom: 5.5,
    },
    Yichun: {
        latitude: '47.7235',
        longitude: '128.8893',
        zoom: 5.5,
    },
    'Tai’an': {
        latitude: '36.2001',
        longitude: '117.0809',
        zoom: 5.5,
    },
    Dazhou: {
        latitude: '31.2152',
        longitude: '107.4947',
        zoom: 5.5,
    },
    Langfang: {
        latitude: '39.5196',
        longitude: '116.7006',
        zoom: 5.5,
    },
    Yongzhou: {
        latitude: '26.4515',
        longitude: '111.5953',
        zoom: 5.5,
    },
    Suihua: {
        latitude: '46.6384',
        longitude: '126.9808',
        zoom: 5.5,
    },
    Qiqihar: {
        latitude: '47.3398',
        longitude: '123.9512',
        zoom: 5.5,
    },
    Suzhou: {
        latitude: '31.3000',
        longitude: '120.6194',
        zoom: 5.5,
    },
    Weinan: {
        latitude: '34.4996',
        longitude: '109.4684',
        zoom: 5.5,
    },
    Zhangzhou: {
        latitude: '24.5093',
        longitude: '117.6612',
        zoom: 5.5,
    },
    Yuncheng: {
        latitude: '35.0304',
        longitude: '110.9980',
        zoom: 5.5,
    },
    Xianyang: {
        latitude: '34.3500',
        longitude: '108.7167',
        zoom: 5.5,
    },
    Guilin: {
        latitude: '25.2819',
        longitude: '110.2864',
        zoom: 5.5,
    },
    Taizhou: {
        latitude: '28.6583',
        longitude: '121.4221',
        zoom: 5.5,
    },
    Bozhou: {
        latitude: '33.8626',
        longitude: '115.7742',
        zoom: 5.5,
    },
    Suqian: {
        latitude: '33.9331',
        longitude: '118.2831',
        zoom: 5.5,
    },
    Huaihua: {
        latitude: '27.5494',
        longitude: '109.9592',
        zoom: 5.5,
    },
    'Ji’an': {
        latitude: '27.1172',
        longitude: '114.9793',
        zoom: 5.5,
    },
    Xiaoganzhan: {
        latitude: '30.9273',
        longitude: '113.9110',
        zoom: 5.5,
    },
    Pingdingshan: {
        latitude: '33.7350',
        longitude: '113.2999',
        zoom: 5.5,
    },
    Jiujiang: {
        latitude: '29.7048',
        longitude: '116.0021',
        zoom: 5.5,
    },
    Mianyang: {
        latitude: '31.4669',
        longitude: '104.7385',
        zoom: 5.5,
    },
    Huanglongsi: {
        latitude: '34.7950',
        longitude: '114.3450',
        zoom: 5.5,
    },
    Changsha: {
        latitude: '28.1987',
        longitude: '112.9709',
        zoom: 5.5,
    },
    Chenzhou: {
        latitude: '25.7989',
        longitude: '113.0267',
        zoom: 5.5,
    },
    Anqing: {
        latitude: '30.5000',
        longitude: '117.0333',
        zoom: 5.5,
    },
    Jiangmen: {
        latitude: '22.5833',
        longitude: '113.0833',
        zoom: 5.5,
    },
    Xinpu: {
        latitude: '34.5906',
        longitude: '119.1801',
        zoom: 5.5,
    },
    Yibin: {
        latitude: '28.7596',
        longitude: '104.6400',
        zoom: 5.5,
    },
    Yangzhou: {
        latitude: '32.3912',
        longitude: '119.4363',
        zoom: 5.5,
    },
    Hengshui: {
        latitude: '37.7348',
        longitude: '115.6860',
        zoom: 5.5,
    },
    Kunming: {
        latitude: '25.0433',
        longitude: '102.7061',
        zoom: 5.5,
    },
    Yiyang: {
        latitude: '28.5833',
        longitude: '112.3333',
        zoom: 5.5,
    },
    Guigang: {
        latitude: '23.0961',
        longitude: '109.6092',
        zoom: 5.5,
    },
    Changchun: {
        latitude: '43.9000',
        longitude: '125.2000',
        zoom: 5.5,
    },
    Jiangguanchi: {
        latitude: '34.0244',
        longitude: '113.8201',
        zoom: 5.5,
    },
    Meizhou: {
        latitude: '24.2998',
        longitude: '116.1191',
        zoom: 5.5,
    },
    Zhangjiakou: {
        latitude: '40.8108',
        longitude: '114.8811',
        zoom: 5.5,
    },
    Chifeng: {
        latitude: '42.2663',
        longitude: '118.9223',
        zoom: 5.5,
    },
    Ürümqi: {
        latitude: '43.8225',
        longitude: '87.6125',
        zoom: 5.5,
    },
    Linfen: {
        latitude: '36.0812',
        longitude: '111.5087',
        zoom: 5.5,
    },
    Shantou: {
        latitude: '23.3735',
        longitude: '116.6941',
        zoom: 5.5,
    },
    Luzhou: {
        latitude: '28.8918',
        longitude: '105.4409',
        zoom: 5.5,
    },
    Hefei: {
        latitude: '31.8639',
        longitude: '117.2808',
        zoom: 5.5,
    },
    Liuzhou: {
        latitude: '24.3264',
        longitude: '109.4281',
        zoom: 5.5,
    },
    Zhaoqing: {
        latitude: '23.0500',
        longitude: '112.4667',
        zoom: 5.5,
    },
    Xiaoxita: {
        latitude: '30.7083',
        longitude: '111.2803',
        zoom: 5.5,
    },
    Shijiazhuang: {
        latitude: '38.0422',
        longitude: '114.5086',
        zoom: 5.5,
    },
    Ningbo: {
        latitude: '29.8750',
        longitude: '121.5492',
        zoom: 5.5,
    },
    Fuzhou: {
        latitude: '26.0769',
        longitude: '119.2917',
        zoom: 5.5,
    },
    Zhuzhou: {
        latitude: '27.8407',
        longitude: '113.1469',
        zoom: 5.5,
    },
    Chuzhou: {
        latitude: '32.3062',
        longitude: '118.3115',
        zoom: 5.5,
    },
    Qingyuan: {
        latitude: '23.6842',
        longitude: '113.0507',
        zoom: 5.5,
    },
    Loudi: {
        latitude: '27.7378',
        longitude: '111.9974',
        zoom: 5.5,
    },
    Binzhou: {
        latitude: '37.3806',
        longitude: '118.0125',
        zoom: 5.5,
    },
    Deyang: {
        latitude: '31.1289',
        longitude: '104.3950',
        zoom: 5.5,
    },
    Taiyuan: {
        latitude: '37.8733',
        longitude: '112.5425',
        zoom: 5.5,
    },
    Wuhu: {
        latitude: '31.3340',
        longitude: '118.3622',
        zoom: 5.5,
    },
    Nanning: {
        latitude: '22.8192',
        longitude: '108.3150',
        zoom: 5.5,
    },
    Harbin: {
        latitude: '45.7500',
        longitude: '126.6333',
        zoom: 5.5,
    },
    Baojishi: {
        latitude: '34.3609',
        longitude: '107.1751',
        zoom: 5.5,
    },
    Zaozhuang: {
        latitude: '34.8667',
        longitude: '117.5500',
        zoom: 5.5,
    },
    Xiamen: {
        latitude: '24.4797',
        longitude: '118.0819',
        zoom: 5.5,
    },
    Neijiang: {
        latitude: '29.5872',
        longitude: '105.0635',
        zoom: 5.5,
    },
    Baicheng: {
        latitude: '45.6148',
        longitude: '122.8320',
        zoom: 5.5,
    },
    Anshan: {
        latitude: '41.1066',
        longitude: '122.9895',
        zoom: 5.5,
    },
    Yulinshi: {
        latitude: '38.2655',
        longitude: '109.7388',
        zoom: 5.5,
    },
    Wenzhou: {
        latitude: '27.9991',
        longitude: '120.6561',
        zoom: 5.5,
    },
    Changzhou: {
        latitude: '31.8122',
        longitude: '119.9692',
        zoom: 5.5,
    },
    Puyang: {
        latitude: '35.7639',
        longitude: '115.0300',
        zoom: 5.5,
    },
    Jiaozuo: {
        latitude: '35.2290',
        longitude: '113.2304',
        zoom: 5.5,
    },
    Nanchang: {
        latitude: '28.6842',
        longitude: '115.8872',
        zoom: 5.5,
    },
    Hechi: {
        latitude: '24.6928',
        longitude: '108.0850',
        zoom: 5.5,
    },
    Hohhot: {
        latitude: '40.8151',
        longitude: '111.6629',
        zoom: 5.5,
    },
    Hanzhong: {
        latitude: '33.0794',
        longitude: '107.0260',
        zoom: 5.5,
    },
    Tangshan: {
        latitude: '39.6292',
        longitude: '118.1742',
        zoom: 5.5,
    },
    Shiyan: {
        latitude: '32.6351',
        longitude: '110.7755',
        zoom: 5.5,
    },
    Siping: {
        latitude: '43.1715',
        longitude: '124.3644',
        zoom: 5.5,
    },
    Changzhi: {
        latitude: '36.1953',
        longitude: '113.0970',
        zoom: 5.5,
    },
    Qinzhou: {
        latitude: '21.9500',
        longitude: '108.6167',
        zoom: 5.5,
    },
    Guiyang: {
        latitude: '26.5794',
        longitude: '106.7078',
        zoom: 5.5,
    },
    Bengbu: {
        latitude: '32.9354',
        longitude: '117.3531',
        zoom: 5.5,
    },
    Bazhou: {
        latitude: '31.8576',
        longitude: '106.7559',
        zoom: 5.5,
    },
    Qincheng: {
        latitude: '34.5809',
        longitude: '105.7311',
        zoom: 5.5,
    },
    Suining: {
        latitude: '30.5098',
        longitude: '105.5737',
        zoom: 5.5,
    },
    Wuxi: {
        latitude: '31.5667',
        longitude: '120.2833',
        zoom: 5.5,
    },
    Leshan: {
        latitude: '29.5854',
        longitude: '103.7575',
        zoom: 5.5,
    },
    Putian: {
        latitude: '25.4394',
        longitude: '119.0103',
        zoom: 5.5,
    },
    Zhenjiang: {
        latitude: '32.2109',
        longitude: '119.4551',
        zoom: 5.5,
    },
    'Guang’an': {
        latitude: '30.4673',
        longitude: '106.6336',
        zoom: 5.5,
    },
    Tongren: {
        latitude: '27.7233',
        longitude: '109.1885',
        zoom: 5.5,
    },
    Qinhuangdao: {
        latitude: '39.9398',
        longitude: '119.5881',
        zoom: 5.5,
    },
    Tongliao: {
        latitude: '43.6172',
        longitude: '122.2640',
        zoom: 5.5,
    },
    Jinzhou: {
        latitude: '41.1144',
        longitude: '121.1292',
        zoom: 5.5,
    },
    Heyuan: {
        latitude: '23.7503',
        longitude: '114.6923',
        zoom: 5.5,
    },
    Xinzhou: {
        latitude: '38.4178',
        longitude: '112.7233',
        zoom: 5.5,
    },
    Lanzhou: {
        latitude: '36.0617',
        longitude: '103.8318',
        zoom: 5.5,
    },
    Wuzhou: {
        latitude: '23.4833',
        longitude: '111.3167',
        zoom: 5.5,
    },
    Chaoyang: {
        latitude: '41.5757',
        longitude: '120.4486',
        zoom: 5.5,
    },
    Shaoguan: {
        latitude: '24.8011',
        longitude: '113.5927',
        zoom: 5.5,
    },
    Shanwei: {
        latitude: '22.7664',
        longitude: '115.3331',
        zoom: 5.5,
    },
    Rizhao: {
        latitude: '35.4164',
        longitude: '119.4331',
        zoom: 5.5,
    },
    Meishan: {
        latitude: '30.0575',
        longitude: '103.8381',
        zoom: 5.5,
    },
    Ningde: {
        latitude: '26.6617',
        longitude: '119.5228',
        zoom: 5.5,
    },
    Zhongshan: {
        latitude: '22.5333',
        longitude: '113.3500',
        zoom: 5.5,
    },
    Weihai: {
        latitude: '37.5000',
        longitude: '122.1000',
        zoom: 5.5,
    },
    Daqing: {
        latitude: '46.5979',
        longitude: '125.0080',
        zoom: 5.5,
    },
    Haikou: {
        latitude: '20.0200',
        longitude: '110.3200',
        zoom: 5.5,
    },
    Xiangtan: {
        latitude: '27.8431',
        longitude: '112.9228',
        zoom: 5.5,
    },
    Mudanjiang: {
        latitude: '44.5861',
        longitude: '129.5997',
        zoom: 5.5,
    },
    Longyan: {
        latitude: '25.0881',
        longitude: '117.0244',
        zoom: 5.5,
    },
    Tieling: {
        latitude: '42.2841',
        longitude: '123.8365',
        zoom: 5.5,
    },
    Baotou: {
        latitude: '40.6562',
        longitude: '109.8345',
        zoom: 5.5,
    },
    Handan: {
        latitude: '36.6116',
        longitude: '114.4894',
        zoom: 5.5,
    },
    Dingxi: {
        latitude: '35.5806',
        longitude: '104.6263',
        zoom: 5.5,
    },
    Nanping: {
        latitude: '26.6448',
        longitude: '118.1728',
        zoom: 5.5,
    },
    Zigong: {
        latitude: '29.3498',
        longitude: '104.7645',
        zoom: 5.5,
    },
    Chaozhou: {
        latitude: '23.6700',
        longitude: '116.6300',
        zoom: 5.5,
    },
    Weifang: {
        latitude: '36.7167',
        longitude: '119.1000',
        zoom: 5.5,
    },
    'Huai’an': {
        latitude: '33.5058',
        longitude: '119.1392',
        zoom: 5.5,
    },
    Zibo: {
        latitude: '36.7831',
        longitude: '118.0497',
        zoom: 5.5,
    },
    Ankang: {
        latitude: '32.6877',
        longitude: '109.0235',
        zoom: 5.5,
    },
    Baoshan: {
        latitude: '25.1211',
        longitude: '99.1690',
        zoom: 5.5,
    },
    Huludao: {
        latitude: '40.7094',
        longitude: '120.8378',
        zoom: 5.5,
    },
    Gulou: {
        latitude: '40.3700',
        longitude: '116.8335',
        zoom: 5.5,
    },
    Yanjiang: {
        latitude: '30.1256',
        longitude: '104.6397',
        zoom: 5.5,
    },
    Sanming: {
        latitude: '26.2658',
        longitude: '117.6302',
        zoom: 5.5,
    },
    Longba: {
        latitude: '33.5350',
        longitude: '105.3490',
        zoom: 5.5,
    },
    Yangjiang: {
        latitude: '21.8556',
        longitude: '111.9627',
        zoom: 5.5,
    },
    Jiamusi: {
        latitude: '46.8081',
        longitude: '130.3653',
        zoom: 5.5,
    },
    Luohe: {
        latitude: '33.5830',
        longitude: '114.0109',
        zoom: 5.5,
    },
    Lincang: {
        latitude: '23.8864',
        longitude: '100.0871',
        zoom: 5.5,
    },
    Xuanzhou: {
        latitude: '30.9475',
        longitude: '118.7518',
        zoom: 5.5,
    },
    Yunfu: {
        latitude: '22.9242',
        longitude: '112.0353',
        zoom: 5.5,
    },
    Shaoxing: {
        latitude: '30.0000',
        longitude: '120.5833',
        zoom: 5.5,
    },
    Yantai: {
        latitude: '37.3997',
        longitude: '121.2664',
        zoom: 5.5,
    },
    Huizhou: {
        latitude: '23.1115',
        longitude: '114.4152',
        zoom: 5.5,
    },
    Lishui: {
        latitude: '28.4500',
        longitude: '119.9167',
        zoom: 5.5,
    },
    Guangyuan: {
        latitude: '32.4353',
        longitude: '105.8398',
        zoom: 5.5,
    },
    Huangshi: {
        latitude: '30.2018',
        longitude: '115.0326',
        zoom: 5.5,
    },
    Xining: {
        latitude: '36.6239',
        longitude: '101.7787',
        zoom: 5.5,
    },
    Dandong: {
        latitude: '40.1167',
        longitude: '124.3833',
        zoom: 5.5,
    },
    Zhuhai: {
        latitude: '22.2769',
        longitude: '113.5678',
        zoom: 5.5,
    },
    Yingkou: {
        latitude: '40.6653',
        longitude: '122.2297',
        zoom: 5.5,
    },
    Lianshan: {
        latitude: '40.7523',
        longitude: '120.8280',
        zoom: 5.5,
    },
    Jilin: {
        latitude: '43.8519',
        longitude: '126.5481',
        zoom: 5.5,
    },
    Yuxi: {
        latitude: '24.3495',
        longitude: '102.5432',
        zoom: 5.5,
    },
    Luoyang: {
        latitude: '34.6587',
        longitude: '112.4245',
        zoom: 5.5,
    },
    Anshun: {
        latitude: '26.2456',
        longitude: '105.9340',
        zoom: 5.5,
    },
    Shangzhou: {
        latitude: '33.8680',
        longitude: '109.9244',
        zoom: 5.5,
    },
    Huainan: {
        latitude: '32.4831',
        longitude: '117.0164',
        zoom: 5.5,
    },
    Kuaidamao: {
        latitude: '41.7302',
        longitude: '125.9471',
        zoom: 5.5,
    },
    'Yan’an': {
        latitude: '36.5952',
        longitude: '109.4863',
        zoom: 5.5,
    },
    Jincheng: {
        latitude: '35.4906',
        longitude: '112.8483',
        zoom: 5.5,
    },
    Nantong: {
        latitude: '31.9829',
        longitude: '120.8873',
        zoom: 5.5,
    },
    Sanmenxia: {
        latitude: '34.7736',
        longitude: '111.1950',
        zoom: 5.5,
    },
    Laibin: {
        latitude: '23.7333',
        longitude: '109.2333',
        zoom: 5.5,
    },
    Qinbaling: {
        latitude: '35.7278',
        longitude: '107.6400',
        zoom: 5.5,
    },
    'Ma’anshan': {
        latitude: '31.6858',
        longitude: '118.5101',
        zoom: 5.5,
    },
    Shengli: {
        latitude: '37.4500',
        longitude: '118.4667',
        zoom: 5.5,
    },
    Gaoping: {
        latitude: '30.7824',
        longitude: '106.1281',
        zoom: 5.5,
    },
    Puning: {
        latitude: '23.2993',
        longitude: '116.1586',
        zoom: 5.5,
    },
    Huaibei: {
        latitude: '33.9562',
        longitude: '116.7890',
        zoom: 5.5,
    },
    Qingyang: {
        latitude: '24.8141',
        longitude: '118.5792',
        zoom: 5.5,
    },
    Chongzuo: {
        latitude: '22.4167',
        longitude: '107.3667',
        zoom: 5.5,
    },
    Yushan: {
        latitude: '31.3867',
        longitude: '120.9766',
        zoom: 5.5,
    },
    Hezhou: {
        latitude: '24.4164',
        longitude: '111.5478',
        zoom: 5.5,
    },
    Pingliang: {
        latitude: '35.5412',
        longitude: '106.6819',
        zoom: 5.5,
    },
    Fushun: {
        latitude: '41.8708',
        longitude: '123.8917',
        zoom: 5.5,
    },
    Pingxiang: {
        latitude: '27.6333',
        longitude: '113.8500',
        zoom: 5.5,
    },
    Yinchuan: {
        latitude: '38.4795',
        longitude: '106.2254',
        zoom: 5.5,
    },
    Xiping: {
        latitude: '40.0820',
        longitude: '113.2981',
        zoom: 5.5,
    },
    Jixi: {
        latitude: '45.2937',
        longitude: '130.9650',
        zoom: 5.5,
    },
    Liaoyang: {
        latitude: '41.2643',
        longitude: '123.1772',
        zoom: 5.5,
    },
    Beihai: {
        latitude: '21.4667',
        longitude: '109.1000',
        zoom: 5.5,
    },
    Fuxin: {
        latitude: '42.0127',
        longitude: '121.6486',
        zoom: 5.5,
    },
    Wuwei: {
        latitude: '37.9278',
        longitude: '102.6329',
        zoom: 5.5,
    },
    Shuyangzha: {
        latitude: '34.1299',
        longitude: '118.7734',
        zoom: 5.5,
    },
    Shuozhou: {
        latitude: '39.3408',
        longitude: '112.4292',
        zoom: 5.5,
    },
    Benxi: {
        latitude: '41.2920',
        longitude: '123.7610',
        zoom: 5.5,
    },
    Baiyin: {
        latitude: '36.5448',
        longitude: '104.1766',
        zoom: 5.5,
    },
    Sanzhou: {
        latitude: '30.8200',
        longitude: '108.4000',
        zoom: 5.5,
    },
    Changshu: {
        latitude: '31.6500',
        longitude: '120.7333',
        zoom: 5.5,
    },
    Heihe: {
        latitude: '50.2458',
        longitude: '127.4886',
        zoom: 5.5,
    },
    Jingdezhen: {
        latitude: '29.2942',
        longitude: '117.2036',
        zoom: 5.5,
    },
    Chengtangcun: {
        latitude: '35.0833',
        longitude: '117.1500',
        zoom: 5.5,
    },
    Hebi: {
        latitude: '35.7497',
        longitude: '114.2887',
        zoom: 5.5,
    },
    Huzhou: {
        latitude: '30.8925',
        longitude: '120.0875',
        zoom: 5.5,
    },
    Zhangjiajie: {
        latitude: '29.1255',
        longitude: '110.4844',
        zoom: 5.5,
    },
    Jiaxing: {
        latitude: '30.7522',
        longitude: '120.7500',
        zoom: 5.5,
    },
    Guiping: {
        latitude: '23.4000',
        longitude: '110.0833',
        zoom: 5.5,
    },
    Lianjiang: {
        latitude: '21.6146',
        longitude: '110.2794',
        zoom: 5.5,
    },
    Ximeicun: {
        latitude: '24.9633',
        longitude: '118.3811',
        zoom: 5.5,
    },
    Jianguang: {
        latitude: '28.1958',
        longitude: '115.7833',
        zoom: 5.5,
    },
    Yucheng: {
        latitude: '29.9888',
        longitude: '103.0007',
        zoom: 5.5,
    },
    Xushan: {
        latitude: '30.1697',
        longitude: '121.2665',
        zoom: 5.5,
    },
    Leizhou: {
        latitude: '20.9147',
        longitude: '110.0806',
        zoom: 5.5,
    },
    Huazhou: {
        latitude: '21.6540',
        longitude: '110.6294',
        zoom: 5.5,
    },
    Jinhua: {
        latitude: '29.1046',
        longitude: '119.6494',
        zoom: 5.5,
    },
    Shuangyashan: {
        latitude: '46.6388',
        longitude: '131.1545',
        zoom: 5.5,
    },
    Pizhou: {
        latitude: '34.3422',
        longitude: '118.0097',
        zoom: 5.5,
    },
    Yangshe: {
        latitude: '31.8775',
        longitude: '120.5512',
        zoom: 5.5,
    },
    Guyuan: {
        latitude: '36.0080',
        longitude: '106.2782',
        zoom: 5.5,
    },
    'Rui’an': {
        latitude: '27.7780',
        longitude: '120.6526',
        zoom: 5.5,
    },
    Wenling: {
        latitude: '28.3797',
        longitude: '121.3718',
        zoom: 5.5,
    },
    Gaozhou: {
        latitude: '21.9135',
        longitude: '110.8481',
        zoom: 5.5,
    },
    Chizhou: {
        latitude: '30.6583',
        longitude: '117.4849',
        zoom: 5.5,
    },
    Fuqing: {
        latitude: '25.7232',
        longitude: '119.3735',
        zoom: 5.5,
    },
    Wuzhong: {
        latitude: '37.9874',
        longitude: '106.1919',
        zoom: 5.5,
    },
    Pingdu: {
        latitude: '36.7833',
        longitude: '119.9556',
        zoom: 5.5,
    },
    Yangquan: {
        latitude: '37.8576',
        longitude: '113.5762',
        zoom: 5.5,
    },
    Yutan: {
        latitude: '28.3147',
        longitude: '112.5540',
        zoom: 5.5,
    },
    Huangshan: {
        latitude: '29.7132',
        longitude: '118.3151',
        zoom: 5.5,
    },
    Liangshi: {
        latitude: '27.2578',
        longitude: '111.7351',
        zoom: 5.5,
    },
    Xintai: {
        latitude: '35.9100',
        longitude: '117.7800',
        zoom: 5.5,
    },
    Wusong: {
        latitude: '30.9333',
        longitude: '117.7667',
        zoom: 5.5,
    },
    Yushu: {
        latitude: '44.8249',
        longitude: '126.5451',
        zoom: 5.5,
    },
    Rongcheng: {
        latitude: '29.8239',
        longitude: '112.9019',
        zoom: 5.5,
    },
    Baishan: {
        latitude: '41.9377',
        longitude: '126.4179',
        zoom: 5.5,
    },
    Dayan: {
        latitude: '26.8808',
        longitude: '100.2208',
        zoom: 5.5,
    },
    Haicheng: {
        latitude: '40.8523',
        longitude: '122.7474',
        zoom: 5.5,
    },
    Jiangyin: {
        latitude: '31.9087',
        longitude: '120.2653',
        zoom: 5.5,
    },
    Yicheng: {
        latitude: '31.3697',
        longitude: '119.8239',
        zoom: 5.5,
    },
    Rucheng: {
        latitude: '32.3852',
        longitude: '120.5634',
        zoom: 5.5,
    },
    Huaiyin: {
        latitude: '33.5819',
        longitude: '119.0280',
        zoom: 5.5,
    },
    Laiwu: {
        latitude: '36.1833',
        longitude: '117.6667',
        zoom: 5.5,
    },
    Jingling: {
        latitude: '30.6667',
        longitude: '113.1667',
        zoom: 5.5,
    },
    Yongcheng: {
        latitude: '33.9317',
        longitude: '116.4459',
        zoom: 5.5,
    },
    Yiwu: {
        latitude: '29.3081',
        longitude: '120.0698',
        zoom: 5.5,
    },
    Beidao: {
        latitude: '34.6020',
        longitude: '105.9180',
        zoom: 5.5,
    },
    Xiangshui: {
        latitude: '26.5964',
        longitude: '104.8314',
        zoom: 5.5,
    },
    Dadukou: {
        latitude: '26.5849',
        longitude: '101.7149',
        zoom: 5.5,
    },
    Lingcheng: {
        latitude: '22.7000',
        longitude: '110.3500',
        zoom: 5.5,
    },
    Xinyu: {
        latitude: '27.7950',
        longitude: '114.9242',
        zoom: 5.5,
    },
    Zhangye: {
        latitude: '38.9355',
        longitude: '100.4553',
        zoom: 5.5,
    },
    Liaoyuan: {
        latitude: '42.8976',
        longitude: '125.1381',
        zoom: 5.5,
    },
    Yingtan: {
        latitude: '28.2333',
        longitude: '117.0000',
        zoom: 5.5,
    },
    Guankou: {
        latitude: '28.1417',
        longitude: '113.6250',
        zoom: 5.5,
    },
    Dingzhou: {
        latitude: '38.5158',
        longitude: '114.9845',
        zoom: 5.5,
    },
    Lianyuan: {
        latitude: '27.6961',
        longitude: '111.6659',
        zoom: 5.5,
    },
    Kaiyuan: {
        latitude: '36.0656',
        longitude: '113.8153',
        zoom: 5.5,
    },
    Zhuji: {
        latitude: '29.7169',
        longitude: '120.2314',
        zoom: 5.5,
    },
    Leiyang: {
        latitude: '26.4179',
        longitude: '112.8457',
        zoom: 5.5,
    },
    Xiantao: {
        latitude: '30.3833',
        longitude: '113.4000',
        zoom: 5.5,
    },
    Yingchuan: {
        latitude: '34.1511',
        longitude: '113.4733',
        zoom: 5.5,
    },
    Xibeijie: {
        latitude: '39.7370',
        longitude: '98.5049',
        zoom: 5.5,
    },
    Yuyao: {
        latitude: '30.0372',
        longitude: '121.1546',
        zoom: 5.5,
    },
    Hanchuan: {
        latitude: '30.6520',
        longitude: '113.8274',
        zoom: 5.5,
    },
    Gongzhuling: {
        latitude: '43.5036',
        longitude: '124.8088',
        zoom: 5.5,
    },
    Zhufeng: {
        latitude: '36.0000',
        longitude: '119.4167',
        zoom: 5.5,
    },
    Ezhou: {
        latitude: '30.3972',
        longitude: '114.8842',
        zoom: 5.5,
    },
    Xiashi: {
        latitude: '30.5333',
        longitude: '120.6833',
        zoom: 5.5,
    },
    Taixing: {
        latitude: '32.1724',
        longitude: '120.0142',
        zoom: 5.5,
    },
    Zhongwei: {
        latitude: '37.5139',
        longitude: '105.1884',
        zoom: 5.5,
    },
    Mizhou: {
        latitude: '35.9900',
        longitude: '119.3801',
        zoom: 5.5,
    },
    Xishan: {
        latitude: '27.6609',
        longitude: '113.4946',
        zoom: 5.5,
    },
    Hegang: {
        latitude: '47.3139',
        longitude: '130.2775',
        zoom: 5.5,
    },
    Fangchenggang: {
        latitude: '21.6000',
        longitude: '108.3000',
        zoom: 5.5,
    },
    Shouguang: {
        latitude: '36.8833',
        longitude: '118.7333',
        zoom: 5.5,
    },
    Sanya: {
        latitude: '18.2533',
        longitude: '109.5036',
        zoom: 5.5,
    },
    Wutong: {
        latitude: '30.6326',
        longitude: '120.5474',
        zoom: 5.5,
    },
    Linhai: {
        latitude: '28.8523',
        longitude: '121.1409',
        zoom: 5.5,
    },
    Wafangdian: {
        latitude: '39.6271',
        longitude: '121.9972',
        zoom: 5.5,
    },
    Zhongxiang: {
        latitude: '31.1690',
        longitude: '112.5853',
        zoom: 5.5,
    },
    Xinyi: {
        latitude: '22.3559',
        longitude: '110.9369',
        zoom: 5.5,
    },
    Zaoyang: {
        latitude: '32.1287',
        longitude: '112.7581',
        zoom: 5.5,
    },
    Xingyi: {
        latitude: '25.0910',
        longitude: '104.9011',
        zoom: 5.5,
    },
    Shuizhai: {
        latitude: '33.4433',
        longitude: '114.8994',
        zoom: 5.5,
    },
    'Zhangjiakou Shi Xuanhua Qu': {
        latitude: '40.5944',
        longitude: '115.0243',
        zoom: 5.5,
    },
    Lianhu: {
        latitude: '34.2585',
        longitude: '108.9242',
        zoom: 5.5,
    },
    Binhu: {
        latitude: '32.0301',
        longitude: '118.7577',
        zoom: 5.5,
    },
    Hongkou: {
        latitude: '31.2500',
        longitude: '121.4892',
        zoom: 5.5,
    },
    Wulaocun: {
        latitude: '32.0344',
        longitude: '118.7958',
        zoom: 5.5,
    },
    Changning: {
        latitude: '31.2174',
        longitude: '121.4211',
        zoom: 5.5,
    },
    Xixiangtang: {
        latitude: '22.8376',
        longitude: '108.3116',
        zoom: 5.5,
    },
    Dayingmen: {
        latitude: '39.1073',
        longitude: '117.2096',
        zoom: 5.5,
    },
    Wuma: {
        latitude: '28.0173',
        longitude: '120.6659',
        zoom: 5.5,
    },
    Yanping: {
        latitude: '26.6381',
        longitude: '118.1682',
        zoom: 5.5,
    },
    Chaowai: {
        latitude: '39.9181',
        longitude: '116.4324',
        zoom: 5.5,
    },
    Liuxiang: {
        latitude: '37.8714',
        longitude: '112.5605',
        zoom: 5.5,
    },
    Changhongjie: {
        latitude: '39.1373',
        longitude: '117.1475',
        zoom: 5.5,
    },
    Yangpu: {
        latitude: '31.2619',
        longitude: '121.5190',
        zoom: 5.5,
    },
    Zhangyelu: {
        latitude: '36.0575',
        longitude: '103.8227',
        zoom: 5.5,
    },
    Jinrongjie: {
        latitude: '39.9122',
        longitude: '116.3562',
        zoom: 5.5,
    },
    Huancuilou: {
        latitude: '37.5004',
        longitude: '122.1140',
        zoom: 5.5,
    },
    Weiyang: {
        latitude: '34.2906',
        longitude: '108.9355',
        zoom: 5.5,
    },
    'Meiyuan Xincun': {
        latitude: '32.0542',
        longitude: '118.7928',
        zoom: 5.5,
    },
    Dongtundu: {
        latitude: '28.1837',
        longitude: '113.0321',
        zoom: 5.5,
    },
    Sanjiang: {
        latitude: '31.3481',
        longitude: '118.3613',
        zoom: 5.5,
    },
    Dongjie: {
        latitude: '26.0882',
        longitude: '119.2945',
        zoom: 5.5,
    },
    Haidian: {
        latitude: '39.9906',
        longitude: '116.2887',
        zoom: 5.5,
    },
    Nanchansi: {
        latitude: '31.5624',
        longitude: '120.3002',
        zoom: 5.5,
    },
    Xinluoqu: {
        latitude: '25.1022',
        longitude: '117.0303',
        zoom: 5.5,
    },
    Daoli: {
        latitude: '45.7559',
        longitude: '126.6034',
        zoom: 5.5,
    },
    Xuhui: {
        latitude: '31.1959',
        longitude: '121.4471',
        zoom: 5.5,
    },
    Xihu: {
        latitude: '36.0673',
        longitude: '103.7765',
        zoom: 5.5,
    },
    Xiaobailou: {
        latitude: '39.1177',
        longitude: '117.2056',
        zoom: 5.5,
    },
    Nangang: {
        latitude: '45.7549',
        longitude: '126.6456',
        zoom: 5.5,
    },
    Pingjianglu: {
        latitude: '31.3147',
        longitude: '120.6298',
        zoom: 5.5,
    },
    'Chong’ansi': {
        latitude: '31.5799',
        longitude: '120.2952',
        zoom: 5.5,
    },
    Xincheng: {
        latitude: '34.2632',
        longitude: '108.9495',
        zoom: 5.5,
    },
    Luwan: {
        latitude: '31.2091',
        longitude: '121.4634',
        zoom: 5.5,
    },
    Chengzhong: {
        latitude: '24.3152',
        longitude: '109.4127',
        zoom: 5.5,
    },
};

export const allCoords = {
    ...countryCoords,
    ...ECStateCoords,
    // ...ECCityCoords,
    ...PHStateCoords,
    ...PHCityCoords,
};
